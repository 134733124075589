import { useAppSelector, useActions, useDebounce } from "app/hooks";
import { ConfigProvider, Flex } from "antd";
import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useState,
    useTransition,
} from "react";
import { HurricaneCheckbox, SearchInput } from "shared/components";
import { useLocation } from "react-router-dom";
import ActionButtons from "../ActionButtons";
import { TableTabItem } from "entities/ExcelTable/types";
import { ResourceConsumptionModal } from "widgets/ExcelTable";
import { loadResourcesGuides } from "entities/ISGuide/api";
import RedButton from "shared/components/RedButton";
import TableTypeSelect from "../TableTypeSelect";
import { MakeTableActiveParams } from "shared/types/is-energy";

type Props = {
    isBottomShowed: boolean;
    toggleBottomShowed: () => void;
    setTrigger: React.Dispatch<React.SetStateAction<boolean>>;
    updateTabs: React.Dispatch<React.SetStateAction<TableTabItem[]>>;
    isLoading: boolean;
    makeTableActive: (params: MakeTableActiveParams) => void;
};

const TableSeracher: FC<Props> = (props) => {
    const { filteredString, showHiddenValues, activeIndex } = useAppSelector(
        (state) => state.Excel
    );
    const location = useLocation();

    const isGuidePage = useMemo(() => {
        return location.pathname.includes("guides");
    }, [location.pathname]);

    const {
        isBottomShowed,
        toggleBottomShowed,
        setTrigger,
        updateTabs,
        isLoading,
        makeTableActive,
    } = props;

    const makeTableActiveFn = useCallback(
        (params: MakeTableActiveParams) => {
            makeTableActive(params);
        },
        [makeTableActive]
    );

    const { setFilterValue, changeShowHiddenValuesStatus } = useActions();

    const [data, setData] = useState(filteredString);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const value = useDebounce<string>(data, 600);
    const [_, startTransition] = useTransition();

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        startTransition(() => setData(e.target.value));
    }

    useEffect(() => {
        if (value !== filteredString) {
            setFilterValue(value);
        }
    }, [value]);

    useEffect(() => {
        return () => {
            setFilterValue("");
        };
    }, []);

    const hasActiveIndex = activeIndex !== null;
    return (
        <ConfigProvider renderEmpty={() => <span>Ничего не найдено...</span>}>
            <ResourceConsumptionModal
                isOpen={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                callback={(data: any) => {
                    return loadResourcesGuides(data, activeIndex?.rate ?? 0);
                }}
                centered
                needFooter={false}
                title="Загрузка норм"
                width={700}
                trigger={() =>
                    new Promise<void>((resolve) =>
                        resolve(setTrigger((prev) => !prev))
                    )
                }
            />
            <Flex align="flex-end" gap={15}>
                <SearchInput
                    className="search"
                    compacted
                    height={55}
                    isLukoil
                    width={150}
                    allowClear
                    style={{ width: 395, height: 44 }}
                    placeholder="Умный поиск"
                    onChange={onChange}
                />

                {!isGuidePage && (
                    <>
                        <HurricaneCheckbox
                            checked={showHiddenValues}
                            style={{ minWidth: 295 }}
                            onChange={() => changeShowHiddenValuesStatus()}
                        >
                            {`${
                                showHiddenValues ? "Скрыть" : "Показать"
                            } скрываемые значения`}
                        </HurricaneCheckbox>{" "}
                        <HurricaneCheckbox
                            checked={isBottomShowed}
                            onChange={() => toggleBottomShowed()}
                        >
                            Сводная таблица
                        </HurricaneCheckbox>
                    </>
                )}

                {/* <FontSizeInput /> */}
                {isGuidePage && hasActiveIndex && (
                    <RedButton
                        variant="gray"
                        onClick={() => setIsModalVisible(true)}
                    >
                        Загрузить нормы
                    </RedButton>
                )}
                {!isGuidePage && (
                    <TableTypeSelect
                        isLoading={isLoading}
                        makeTableActive={makeTableActiveFn}
                    />
                )}

                <ActionButtons
                    updateTabs={updateTabs}
                    setTrigger={setTrigger}
                />
            </Flex>
        </ConfigProvider>
    );
};

export default TableSeracher;
