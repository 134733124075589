import Modal from "antd/es/modal/Modal";
import { FC, useEffect, useMemo, useState } from "react";
import RadioTabs from "shared/components/RadioTabs";
import { Flex } from "antd";
import { AddButton } from "components/UI";
import styles from "./styles.module.css";
import { useAppSelector } from "app/hooks";
import { SerializedObject } from "shared/SerializedObject";
import { availableYears, currentYear } from "shared/constants/common";

type Props = {
    isOpen: boolean;
    closeOpen: () => void;
    onCreateItem: (item: { [key: string]: string }) => void;
};

const defaultValue: Filter = {
    Energy: "",
    Period: currentYear,
    Mode: "",
    EnergySourceId: "",
};

type Filter = {
    Energy: string;
    Period: string;
    Mode: string;
    EnergySourceId: string;
};

const GuideCreatingModal: FC<Props> = (props) => {
    const { isOpen, closeOpen, onCreateItem } = props;

    const { guideCreatingForm, isEditingModalStatus } = useAppSelector(
        (state) => state.Excel
    );

    const [filter, setFilter] = useState<SerializedObject<Filter>>(
        new SerializedObject<Filter>(defaultValue)
    );

    useEffect(() => {
        if (
            !isOpen &&
            JSON.stringify(filter) !== JSON.stringify(defaultValue)
        ) {
            setFilter(filter.clone(defaultValue));
        }
    }, [isOpen]);

    const allItems = useMemo(() => {
        return [
            ...guideCreatingForm.form,
            ...[
                {
                    name: "Mode",
                    items: [
                        {
                            items: [
                                {
                                    key: "Постоянные",
                                    value: "CONST",
                                },
                                {
                                    key: "Переменная",
                                    value: "VARIABLE",
                                },
                            ],
                        },
                    ],
                },
                {
                    name: "Period",
                    itemsInLine: 4,
                    startIndex: 3,
                    items: [{ items: availableYears }],
                },
            ],
        ];
    }, [filter, guideCreatingForm]);

    return (
        <Modal
            open={isOpen}
            title="Внести норматив"
            onClose={closeOpen}
            width={900}
            centered
            height={400}
            footer={false}
            onCancel={closeOpen}
        >
            <div className={styles["guide-creating__modal"]}>
                <Flex vertical gap={8}>
                    {allItems.map((el) => {
                        return (
                            <RadioTabs
                                key={el.name}
                                value={filter.obj[el.name as keyof Filter]}
                                itemsinLine={el.itemsInLine}
                                startIndex={
                                    el.name === "Period" ? 3 : undefined
                                }
                                dependsOn={
                                    el.dependsOnName
                                        ? filter.obj[
                                              el.dependsOnName as keyof Filter
                                          ]
                                        : undefined
                                }
                                values={el.items}
                                changeString={(val: string) =>
                                    setFilter((prev) => {
                                        return prev.clone({
                                            ...prev.obj,
                                            [el.name]: val,
                                        });
                                    })
                                }
                            />
                        );
                    })}
                </Flex>
                <AddButton
                    className={styles["guide-creating__btn"]}
                    disabled={!filter.isSerialized}
                    onClick={() => {
                        console.log(filter.obj)
                        onCreateItem(filter.obj);
                        closeOpen();
                    }}
                >
                    {isEditingModalStatus === "edit" ? "Применить" : "Создать"}
                </AddButton>
            </div>
        </Modal>
    );
};

export default GuideCreatingModal;
