import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "helpers/getAuth";
import { urlApi } from "app/axiosInstance";
import { PeriodConfiguration, TableTabItem, TabsParams } from "../types";
import { setActiveTable } from ".";
import { createRate, createReport } from "entities/ISGuide/api";
import { IRatesParams } from "entities/ISGuide/types";
import { MakeTableActiveParams } from "shared/types/is-energy";

const auth = getAuth();

type TabParamName = "reportTemplateId" | "rateTemplateId";

const tabsEndpoints: Record<
    TabsParams,
    { url: string; paramName: TabParamName } | null
> = {
    balance: { url: "ReportTemplate/active", paramName: "reportTemplateId" },
    guide: { url: "RateTemplate/active", paramName: "rateTemplateId" },
};

export const ISApi = createApi({
    reducerPath: "IS-Energy",
    baseQuery: fetchBaseQuery({
        baseUrl: urlApi + "/rest",
        prepareHeaders: (headers) => {
            if (auth?.headers?.Authorization) {
                headers.set(
                    "Authorization",
                    `Basic ` + auth.headers.Authorization
                );
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["Tabs"],
    endpoints: (builder) => ({
        getActiveTabs: builder.query<
            TableTabItem[],
            {
                type: TabsParams;
            }
        >({
            query: (params) => {
                const actualEndpoint = tabsEndpoints[params.type];
                return {
                    url: `/${actualEndpoint!.url}`,
                };
            },
            providesTags: ["Tabs"],
        }),
        removeTab: builder.mutation<
            undefined,
            {
                type: TabsParams;
                tabId: number;
            }
        >({
            query: (params) => {
                const actualEndpoint = tabsEndpoints[params.type]!;
                return {
                    url: `/${actualEndpoint.url}?${[
                        actualEndpoint.paramName,
                    ]}=${params.tabId}`,
                    method: "DELETE",
                };
            },
            invalidatesTags: ["Tabs"],
        }),
        makeTabActive: builder.mutation<
            undefined,
            {
                type: TabsParams;
                tabId: number;
            }
        >({
            query: (params) => {
                const actualEndpoint = tabsEndpoints[params.type]!;
                return {
                    url: `/${actualEndpoint.url}?${[
                        actualEndpoint.paramName,
                    ]}=${params.tabId}`,
                    method: "PUT",
                };
            },
            invalidatesTags: ["Tabs"],
        }),
        makeTableActive: builder.mutation<
            any,
            MakeTableActiveParams
        >({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    await setActiveTable(arg);
                    return { data: undefined };
                } catch (e) {
                    return { error: undefined };
                }
            },
            invalidatesTags: ["Tabs"],
        }),
        createReport: builder.mutation<
            any,
            PeriodConfiguration & {
                resourceId: number;
                name: string;
                shortName: string;
            }
        >({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    const response = await createReport(arg);
                    return { data: response };
                } catch (err) {
                    return { error: undefined };
                }
            },
            invalidatesTags: ["Tabs"],
        }),
        createRate: builder.mutation<any, IRatesParams>({
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                try {
                    const response = await createRate(arg);
                    return { data: response };
                } catch (err) {
                    return { error: undefined };
                }
            },
        }),
    }),
});
