import React, { FC, useMemo } from "react";
import { Col, Input, Row } from "antd";
import { IFilter } from "shared/types/filters";
import styles from "./styles.module.css";
import FiltersDatePicker from "../FiltersDatePicker";
import { HurricaneInput, HurricaneSelect } from "shared/components";

type Props = {
    filters: IFilter[];
    itemsInRow?: number;
    value: {
        [key: string]: string | null | string[];
    };
    onChangeFilters: (key: string, value: string | null) => void;
};

const FiltersGrid: FC<Props> = (props) => {
    const { filters, itemsInRow, value, onChangeFilters } = props;

    const columnSpan = useMemo(() => {
        return 24 / (itemsInRow ?? filters.length);
    }, [filters.length, itemsInRow]);

    return (
        <Row className={styles["filter-grid"]} gutter={10}>
            {filters.map((filter) => (
                <Col span={columnSpan} key={filter.name}>
                    {filter.type === "Date" ? (
                        <FiltersDatePicker
                            placeholder={filter.placeholder}
                            value={value[filter.name] as string | null}
                            onChangeFilters={(val: string | null) =>
                                onChangeFilters(filter.name, val)
                            }
                        />
                    ) : filter.type === "Input" ? (
                        <HurricaneInput
                            placeholder={filter.placeholder ?? "Введите"}
                            type="text"
                            value={value[filter.name] ?? ""}
                            allowClear
                            onChange={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => onChangeFilters(filter.name, e.target.value)}
                        />
                    ) : (
                        <HurricaneSelect
                            className={styles["filter-select"]}
                            placeholder={filter.placeholder ?? "Выберите"}
                            allowClear
                            showSearch
                            multiple={filter.isMulti}
                            onClear={() => {
                                onChangeFilters(filter.name, null);
                            }}
                            onChange={(val: string) =>
                                onChangeFilters(filter.name, val)
                            }
                            value={value[filter.name] ?? undefined}
                            options={filter.options?.map((option) => ({
                                value: option.value,
                                label: option.key,
                            }))}
                        />
                    )}
                </Col>
            ))}
        </Row>
    );
};

export default FiltersGrid;
