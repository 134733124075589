import { useState, useEffect, useMemo, useCallback } from "react";
import { ChildrenCell, ITemplate } from "../types";

export const useGuideChildrenCells = (templates?: ITemplate[] | null) => {
    const [childrenCells, setChildrenCells] = useState<ChildrenCell[]>([]);
    const [globalOpenStatus, setGlobalOpenStatus] = useState<{
        status: ChildrenCell["status"];
        trigger: boolean;
    }>({ status: false, trigger: true });

    const replaceGlobalStatus = useCallback(
        (status: ChildrenCell["status"]) => {
            setGlobalOpenStatus((prev) => ({ status, trigger: !prev.trigger }));
            setChildrenCells((prev) => prev.map((el) => ({ ...el, status })));
        },
        [setGlobalOpenStatus]
    );

    const toggleChildren = (
        key: ChildrenCell["key"],
        value: ChildrenCell["status"]
    ) => {
        setChildrenCells((prev) =>
            prev.map((el) => (el.key === key ? { ...el, status: value } : el))
        );
    };

    useEffect(() => {
        if (!templates) {
            setChildrenCells([]);
            return;
        }
        setChildrenCells(
            templates
                .filter((el) => el.Children?.length)
                .map((el) => ({ key: el.PositionId, status: false }))
        );
    }, [templates]);

    const isAllClosed = useMemo<boolean>(() => {
        return childrenCells.every((el) => !el.status);
    }, [childrenCells]);

    return {
        isAllClosed,
        toggleChildren,
        replaceGlobalStatus,
        globalOpenStatus,
    };
};
