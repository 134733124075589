import { useEffect, useState } from "react";
import { IndexedTablesInfo } from "shared/types/is-energy";

const DB_NAME = "IsEnergy";
const DB_VERSION = 1;
const STORE_NAME = "reports";
type TableIdType = IndexedTablesInfo["tableId"];

const openDB = () => {
    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, 1);

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            const db: IDBDatabase = (event.target as IDBOpenDBRequest).result;

            // Создаем объектное хранилище, если его нет
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                const objectStore: IDBObjectStore = db.createObjectStore(
                    STORE_NAME,
                    {
                        keyPath: "id",
                        autoIncrement: true,
                    }
                );

                // Создаем индексы
                // objectStore.createIndex("name", "name", { unique: false });
                objectStore.createIndex("tableId", "tableId", {
                    unique: true,
                });
            }
        };

        request.onsuccess = (event: Event) => {
            resolve((event.target as IDBOpenDBRequest).result);
        };

        request.onerror = (event) => {
            reject((event.target as IDBOpenDBRequest).result);
        };
    });
};

export const useIndexedDB = () => {
    const [request, setRequest] = useState<IDBDatabase | null | undefined>(
        undefined
    );
    const [indexedTables, setIndexedTables] = useState<IndexedTablesInfo[]>([]);

    useEffect(() => {
        function onOpenDb() {
            openDB()
                .then(setRequest)
                .catch(() => {
                    setRequest(null);
                });
        }
        onOpenDb();
    }, []);

    function deleteTableByTableId(db: IDBDatabase, tableId: TableIdType) {
        const transaction: IDBTransaction = db.transaction(
            [STORE_NAME],
            "readwrite"
        );
        const objectStore: IDBObjectStore = transaction.objectStore(STORE_NAME);
        const index: IDBIndex = objectStore.index("tableId");
        const request = index.get(tableId);

        request.onsuccess = (event: Event) => {
            const record = (event.target as IDBRequest<IndexedTablesInfo>)
                .result;
            if (record) {
                // Удаляем элемент по его ключу
                const deleteRequest = objectStore.delete(record.id!); // Предположим, что `id` — это ключ записи

                deleteRequest.onsuccess = () => {
                    setIndexedTables((prev) =>
                        prev.filter((el) => el.tableId !== record.tableId)
                    );
                };
                deleteRequest.onerror = () => {
                    console.error("Ошибка при удалении записи");
                };
            } else {
                console.log("Запись не найдена");
            }
        };

        request.onerror = () => {
            console.error("Ошибка при поиске записи");
        };
    }

    const deleteIndexedTable = (
        tableId: Parameters<typeof deleteTableByTableId>[1]
    ) => {
        if (request) {
            deleteTableByTableId(request, tableId);
        }
    };

    function readTables(db: IDBDatabase) {
        const transaction: IDBTransaction = db.transaction(
            [STORE_NAME],
            "readonly"
        );
        const objectStore: IDBObjectStore = transaction.objectStore(STORE_NAME);

        const request: IDBRequest<IndexedTablesInfo[]> = objectStore.getAll();

        request.onsuccess = (event: Event) => {
            const tables: IndexedTablesInfo[] = (
                event.target as IDBRequest<IndexedTablesInfo[]>
            ).result;
            setIndexedTables(tables);
        };

        request.onerror = (event: Event) => {
            console.error(
                "Ошибка при чтении данных:",
                (event.target as IDBRequest).error
            );
        };
    }

    const readIndexedTables = () => {
        if (request) {
            readTables(request);
        }
    };

    function togglePointedRow(
        db: IDBDatabase,
        tableId: TableIdType,
        rowId: number
    ) {
        const transaction: IDBTransaction = db.transaction(
            [STORE_NAME],
            "readwrite"
        );
        const objectStore: IDBObjectStore = transaction.objectStore(STORE_NAME);
        const index: IDBIndex = objectStore.index("tableId");

        const getRequest: IDBRequest<IndexedTablesInfo> = index.get(tableId);

        getRequest.onsuccess = (event: Event) => {
            const table: IndexedTablesInfo = (
                event.target as IDBRequest<IndexedTablesInfo>
            ).result;
            if (table) {
                const pointedRows = table.pickedRows;
                const index = pointedRows.indexOf(rowId);
                if (index === -1) {
                    pointedRows.push(rowId);
                } else {
                    pointedRows.splice(index, 1);
                }

                const updatedTable: IndexedTablesInfo = {
                    ...table,
                    pickedRows: pointedRows,
                };
                const updateRequest: IDBRequest = objectStore.put(updatedTable);

                updateRequest.onsuccess = () => {
                    setIndexedTables((prev) =>
                        prev.map((el) =>
                            el.tableId === tableId ? updatedTable : el
                        )
                    );
                };

                updateRequest.onerror = (event: Event) => {
                    console.error((event.target as IDBRequest).error);
                };
            } else {
                console.error(`Ошбика`);
            }
        };
    }

    const updatePointedRow = (
        tableId: Parameters<typeof togglePointedRow>[1],
        rowId: Parameters<typeof togglePointedRow>[2]
    ) => {
        if (request) {
            togglePointedRow(request, tableId, rowId);
        }
    };

    function updateTable(
        db: IDBDatabase,
        tableId: TableIdType,
        updatedData: Partial<IndexedTablesInfo>
    ) {
        const transaction: IDBTransaction = db.transaction(
            [STORE_NAME],
            "readwrite"
        );
        const objectStore: IDBObjectStore = transaction.objectStore(STORE_NAME);
        const index: IDBIndex = objectStore.index("tableId");

        const getRequest: IDBRequest<IndexedTablesInfo> = index.get(tableId);

        getRequest.onsuccess = (event: Event) => {
            const table: IndexedTablesInfo = (
                event.target as IDBRequest<IndexedTablesInfo>
            ).result;
            if (table) {
                // Обновляем данные пользователя
                const updatedTable: IndexedTablesInfo = {
                    ...table,
                    ...updatedData,
                };
                const updateRequest: IDBRequest = objectStore.put(updatedTable);

                updateRequest.onsuccess = () => {
                    setIndexedTables((prev) =>
                        prev.map((el) =>
                            el.tableId === tableId
                                ? { ...el, ...updatedData }
                                : el
                        )
                    );
                };

                updateRequest.onerror = (event: Event) => {
                    console.error((event.target as IDBRequest).error);
                };
            } else {
                console.error(`Ошбика`);
            }
        };

        getRequest.onerror = (event: Event) => {
            console.error(
                "Ошибка при получении данных:",
                (event.target as IDBRequest).error
            );
        };
    }

    type EditParams = Parameters<typeof updateTable>;

    const updateIndexedTable = (
        tableId: EditParams[1],
        updatedData: EditParams[2]
    ) => {
        if (request) {
            updateTable(request, tableId, updatedData);
        }
    };

    function addTable(db: IDBDatabase, table: IndexedTablesInfo) {
        const transaction: IDBTransaction = db.transaction(
            [STORE_NAME],
            "readwrite"
        );
        const objectStore: IDBObjectStore = transaction.objectStore(STORE_NAME);

        objectStore.add(table);

        transaction.oncomplete = () => {
            setIndexedTables((prev) => [...prev, table]);
        };

        transaction.onerror = (event: Event) => {
            console.error(
                "Ошибка транзакции:",
                (event.target as IDBRequest).error
            );
        };
    }

    const addIndexedTable = (table: Parameters<typeof addTable>[1]) => {
        if (request) {
            addTable(request, table);
        }
    };

    return {
        deleteIndexedTable,
        addIndexedTable,
        updateIndexedTable,
        readIndexedTables,
        request,
        updatePointedRow,
        indexedTables,
    };
};
