import { Modal } from "antd";
import { DefaultModalFooter } from "shared/components";
import { ModalProps } from "shared/types";

export const withAntdModal = <P extends object>(
    Component: React.ComponentType<P & ModalProps>
) => {
    return (props: P & ModalProps) => {
        const {
            isOpen,
            onClose,
            width,
            centered,
            needFooter,
            title,
            onAccept,
            acceptText,
            ...rest
        } = props;

        return (
            <Modal
                centered={!!centered}
                width={width}
                title={title}
                open={isOpen}
                onCancel={onClose}
                onClose={onClose}
                footer={
                    needFooter !== false ? (
                        () => (
                            <DefaultModalFooter
                                onAccept={onAccept?.() ?? (() => {})}
                                acceptText={acceptText}
                                onReject={onClose}
                            />
                        )
                    ) : (
                        <></>
                    )
                }
            >
                <Component {...(props as P & ModalProps)} />
            </Modal>
        );
    };
};
