import "./styles/App.scss";
import "./styles/normalize.css";
import "./styles/fonts.css";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Header from "./components/Header/header";
import { useGetCurrentUser } from "./hooks/currentUserHook";
import { BigLoader } from "./components/UI";
import ProtectedRoute from "./shared/components/ProtectedRoute";
import { ConfigProvider } from "antd";
import { useActions } from "./app/hooks";
import { useEffect } from "react";
import { useIndexedDB } from "hooks";
import { IndexedContext } from "processes/indexedDBContext";

function App() {
    const { status, realRoutes } = useGetCurrentUser();
    const { getFromLocalStorage, } = useActions();

    const isLoading: boolean = status === "pending";

    const { actualRoutes, defaultPath } = realRoutes;
    const {
        request,
        indexedTables,
        deleteIndexedTable,
        addIndexedTable,
        updateIndexedTable,
        readIndexedTables,
        updatePointedRow
    } = useIndexedDB();

    useEffect(() => {
        getFromLocalStorage();
        
    }, []);

    useEffect(() => {
        if (request === null) window.location.reload();
        if (request) {
            readIndexedTables()
        }
    }, [request]);

    if (request === undefined) return null;
    return (
        <IndexedContext.Provider
            value={{
                deleteIndexedTable,
                addIndexedTable,
                updateIndexedTable,
                readIndexedTables,
                indexedTables,
                updatePointedRow
            }}
        >
            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            primaryColor: "#e22739",
                            colorIconHover: "#e22739",
                            colorInfoTextHover: "#e22739",
                            defaultHoverColor: "#e22739",
                            defaultHoverBorderColor: "#e22739",
                            defaultActiveColor: "#e22739",
                            defaultActiveBorderColor: "#e22739",
                        },
                    },
                }}
            >
                <div className="event-page-container">
                    <Header realRoutes={realRoutes} />
                    {isLoading ? (
                        <BigLoader />
                    ) : (
                        <Routes>
                            {actualRoutes.map(
                                ({
                                    Component,
                                    children,
                                    path,
                                    name,
                                    permission,
                                    MotherComponent,
                                }) => (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={
                                            <ProtectedRoute
                                                name={name}
                                                permission={permission ?? null}
                                            >
                                                {children?.length ? (
                                                    MotherComponent ? (
                                                        MotherComponent
                                                    ) : (
                                                        <Outlet />
                                                    )
                                                ) : (
                                                    Component
                                                )}
                                            </ProtectedRoute>
                                        }
                                    >
                                        {children && (
                                            <>
                                                <Route
                                                    index
                                                    element={
                                                        <ProtectedRoute
                                                            name={name}
                                                            permission={
                                                                permission ??
                                                                null
                                                            }
                                                        >
                                                            {Component}
                                                        </ProtectedRoute>
                                                    }
                                                />
                                                {children.map((el) => (
                                                    <Route
                                                        path={el.path}
                                                        key={el.path}
                                                        element={
                                                            <ProtectedRoute
                                                                name={el.name}
                                                                permission={
                                                                    permission ??
                                                                    null
                                                                }
                                                            >
                                                                {el.Component}
                                                            </ProtectedRoute>
                                                        }
                                                    />
                                                ))}
                                            </>
                                        )}
                                    </Route>
                                )
                            )}

                            <Route
                                path="*"
                                element={<Navigate to={defaultPath} />}
                            />
                        </Routes>
                    )}
                </div>
            </ConfigProvider>
        </IndexedContext.Provider>
    );
}

export default App;
