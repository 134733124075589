import { Flex } from "antd";
import { RcFile } from "antd/es/upload";
import { CallbackButton, HurricaneFile } from "shared/components";
import React, { useState } from "react";
import { ApiModule } from "shared/ApiModule";
import { callError } from "shared/helpers";

const DownloadExcel = React.memo(() => {
    async function sendFile() {
        const formData = new FormData();
        formData.append("file", fileList[0]);
        const response = await ApiModule.post<
            undefined | { Message: string },
            FormData
        >("/ResourceConsumptionConfiguration/excel", formData);
    }

    const [fileList, setFileList] = useState<RcFile[]>([]);

    return (
        <Flex vertical gap={12}>
            <h3>Загрузить конфигурацию</h3>
            <HurricaneFile fileList={fileList} modifiedFileList={setFileList} />

            <CallbackButton disabled={!fileList.length} onClick={sendFile}>
                Отправить
            </CallbackButton>
        </Flex>
    );
});

export default DownloadExcel;
