import { useEffect, useMemo, useRef, useState } from "react";
import {
    PeriodConfiguration,
    TablePageProps,
    TableTabItem,
    TabsParams,
} from "../types";
import { useActions, useAppSelector } from "app/hooks";
import { Flex, Modal } from "antd";
import { TableHeader, TableSearcher } from "../components";
import { callSuccess } from "shared/helpers";
import { useIndexedContext } from "processes/indexedDBContext";
import { rtkHooks } from "app/store";
import { IRatesParams } from "entities/ISGuide/types";

export const withExcelPage = (
    Component: React.ComponentType<TablePageProps>,
    type: TabsParams,
    renameFunction: (data: { [key: string]: any }) => string,
    setShortName: (data: { [key: string]: any }) => string,
    newTabText: string
) => {
    return () => {
        const { titleGenerator, activeIndex, isEditingModalStatus, promises } =
            useAppSelector((state) => state.Excel);
        const { addIndexedTable } = useIndexedContext();

        const isEditableMode = isEditingModalStatus === "edit";
        const [createReport] = rtkHooks.useCreateReportMutation();
        const [createRate] = rtkHooks.useCreateRateMutation();
        const [isActiveMutation, setIsActiveMutation] =
            useState<boolean>(false);
        const [makeTableActive, { isLoading }] =
            rtkHooks.useMakeTableActiveMutation();

        function updateActiveIndex(item?: TableTabItem) {
            if (item) {
                changeActiveIndex({
                    rate: item.Id,
                    table:
                        item.ReportTableId ??
                        item.ReportTemplateTableDtos?.find((el) => el.IsActive)
                            ?.ReportTableId ??
                        0,
                    name: item.Name,
                    dtos: item.ReportTemplateTableDtos ?? [],
                });
            } else {
                changeActiveIndex(null);
            }
        }

        const updateNewItem = (val: { [key: string]: any }) => {
            if (isEditableMode) {
                modifiyTabs(val);
            } else {
                modifiyTabs(val);
            }
        };

        const createNewTab = (item: TableTabItem) => {
            if (tabs.some((tab) => tab.Id === item.Id)) {
                updateActiveIndex(item);
                return;
            }
            const index =
                item.ReportTableId ??
                item.ReportTemplateTableDtos?.find((el) => el.IsActive)
                    ?.ReportTableId;
            if (index) {
                addIndexedTable({
                    tableId: index,
                    pickedRows: [],
                    openedRows: [],
                });
            }

            // setTabs((prev) => [...prev, item]);
        };

        async function modifiyTabs(newItemModel?: {
            [key: string]: string | number | null;
        }) {
            const obj = newItem ?? newItemModel;
            if (!obj) return;

            setIsActiveMutation(true);
            if (type === "balance") {
                const data = await createReport(
                    obj as unknown as PeriodConfiguration & {
                        resourceId: number;
                        name: string;
                        shortName: string;
                    }
                );
                if ("data" in data) {
                    addIndexedTable({
                        tableId: data.data.tableId,
                        pickedRows: [],
                        openedRows: [],
                    });
                }
            } else {
                const newItemId = await createRate(
                    obj as unknown as IRatesParams
                );
                if (!("data" in newItemId)) return;
                // если сейчас есть newItem, значит, мы нажали на кнопку "Принять" при подтверждении. Этот процесс не обрабатываем. а просто заменяем табчик (написано на момент 05.12, бека для редактирования нет)
                if (!!newItem || isEditableMode) {
                    // setTabs((prev) =>
                    //     prev.map((el) =>
                    //         el.Id === activeIndex?.rate
                    //             ? { ...el, filtersState: obj }
                    //             : el
                    //     )
                    // );
                } else {
                    // в противном случае (который говорит о том, что мы создаем элемент) смотрим на результат запроса. На момент 05.12 ошибка могла возникнуть при попытке создать отчет по нормам, который уже есть в архиве
                    if (newItemId.data.success) {
                        createNewTab({
                            Id: newItemId.data.id,
                            ReportTemplateTableDtos: [],
                            ReportTableId: newItemId.data.tableId,

                            Name: newItemId.data.name || titleGenerator(obj),
                            ShortName: newItemId.data.name
                                ? newItemId.data.name
                                      .split("-")[0]
                                      .split("[")[0]
                                : setShortName(obj),
                            filtersState: obj,
                        });
                        refetch();
                    } else {
                        setErrorId(newItemId.data.id);
                    }
                }
                if (newItemId.data.success || isEditableMode) {
                    callSuccess(
                        `Отчет ${isEditableMode ? "изменен" : "сформирован"}`
                    );
                }
            }

            setNewItem(null);
            setCustomName();
            setIsActiveMutation(false);
        }

        const [tabs, setTabs] = useState<TableTabItem[]>([]);
        const [trigger, setTrigger] = useState<boolean>(false);
        const [newItem, setNewItem] = useState<{
            [key: string]: string | number | null;
        } | null>(null);

        const [errorId, setErrorId] = useState<number | null>(null);

        const {
            changeActiveIndex,
            regenerateTitle,
            clearEditings,
            changeShowHiddenValuesStatus,
            changeEditingModalOpeningStatus,
            setCustomName,
        } = useActions();

        const currentTableItem = useMemo<TableTabItem | undefined>(() => {
            return tabs.find((item) => item.Id === activeIndex?.rate);
        }, [activeIndex, tabs]);

        const {
            data,
            isLoading: isLoadingTabs,
            isError,
            refetch,
        } = rtkHooks.useGetActiveTabsQuery(
            { type },
            { refetchOnMountOrArgChange: true }
        );
        // сохраняет старую предыдущую версию даты
        const dataBackup = useRef<TableTabItem[] | null>(null);

        useEffect(() => {
            if (data && !isError) {
                const actualItem =
                    dataBackup.current &&
                    data.length > dataBackup.current.length
                        ? data.at(-1)
                        : data.find((el) => el.Id === activeIndex?.rate) ??
                          data[0];
                dataBackup.current = data;
                setTabs(data);

                if (actualItem) {
                    const {
                        Id,

                        ReportTableId,
                        ReportTemplateTableDtos,
                        Name,
                    } = actualItem;
                    const tableId =
                        ReportTableId ??
                        ReportTemplateTableDtos?.find((el) => el.IsActive)
                            ?.ReportTableId ??
                        0;
                    const actualObj = {
                        rate: Id,
                        name: Name,
                        table: tableId,
                        dtos: ReportTemplateTableDtos ?? [],
                    };
                    if (
                        !activeIndex ||
                        JSON.stringify(activeIndex) !==
                            JSON.stringify(actualObj)
                    )
                        changeActiveIndex(actualObj);
                } else {
                    changeActiveIndex(null);
                }
            }

            regenerateTitle(renameFunction);
        }, [data, isError]);

        useEffect(() => {
            return () => {
                changeActiveIndex(undefined);
                clearEditings();
                changeShowHiddenValuesStatus(false);
            };
        }, []);

        const [isEditingModalOpen, setIsEditingModalOpen] =
            useState<boolean>(false);

        const onCreateItem = () => {
            if (Object.keys(promises).length) {
                setIsEditingModalOpen(true);
            } else {
                changeEditingModalOpeningStatus("creating");
            }
        };
        const cancelModal = () => {
            changeEditingModalOpeningStatus(false);
            setNewItem(null);
        };

        const [isBottomTableShowed, setIsBottomTableShowed] = useState(true);

        const toggleBottomShowed = (val?: boolean) => {
            setIsBottomTableShowed((prev) => {
                const finalValue = val !== undefined ? val : !prev;
                if (finalValue) {
                    const elem = document.querySelector(
                        '[data-type="summary"]'
                    );
                    if (elem) {
                        elem.scrollIntoView({
                            behavior: "smooth",
                            block: "end",
                            inline: "start",
                        });
                    }
                }

                return finalValue;
            });
        };

        return (
            <Flex vertical gap={15}>
                <TableHeader
                    items={tabs}
                    type={type}
                    createTabTitle={newTabText}
                    onCreateItem={onCreateItem}
                    createTab={createNewTab}
                    isTabsLoading={isLoadingTabs}
                />

                <Modal
                    open={isEditingModalOpen}
                    title="Внимание"
                    centered
                    width={800}
                    onCancel={() => setIsEditingModalOpen(false)}
                    onClose={() => setIsEditingModalOpen(false)}
                    footer={() => <></>}
                >
                    <strong>
                        В ваших текущих отчетах имеются несохраненные изменения.
                        Примите их или сбросьте их перед тем, как создать новый
                        отчет
                    </strong>
                </Modal>

                <TableSearcher
                    isBottomShowed={isBottomTableShowed}
                    toggleBottomShowed={toggleBottomShowed}
                    setTrigger={setTrigger}
                    updateTabs={setTabs}
                    isLoading={isLoading}
                    makeTableActive={makeTableActive}
                />

                {activeIndex !== undefined && !isLoadingTabs && (
                    <Component
                        cancelModal={cancelModal}
                        createNewTab={createNewTab}
                        trigger={trigger}
                        isActiveMutation={isActiveMutation || isLoading}
                        errorId={errorId}
                        isBottomTableShowed={isBottomTableShowed}
                        cleanErrorId={() => setErrorId(null)}
                        currentTableItem={currentTableItem}
                        updateNewItem={updateNewItem}
                    />
                )}
            </Flex>
        );
    };
};
