import { FC } from "react";
import { getRealTable } from "entities/ExcelTable/api";
import { TablePageProps } from "entities/ExcelTable/types";
import { useEffectSkipFirstRender, useFetch } from "hooks";
import { getGuideName } from "ExcelTable/helpers/methods";
import {
    GuideCreatingModal,
    GuideExistModal,
} from "entities/ISGuide/components";
import { withExcelPage } from "entities/ExcelTable/hocs/withExcelPage";
import { getRates } from "entities/ISGuide/api";
import { useActions, useAppSelector } from "app/hooks";
import { useArchiveToReport } from "entities/ExcelTable/hooks/useArchiveToReport";
import { TableBlock } from "widgets/ExcelTable";
import { rtkHooks } from "app/store";

const GuidePage: FC<TablePageProps> = (props) => {
    const {
        currentTableItem,
        updateNewItem,
        createNewTab,
        errorId,
        cleanErrorId,
        trigger,
    } = props;

    const { activeIndex, isEditingModalStatus } = useAppSelector(
        (state) => state.Excel
    );
    const { changeActiveIndex, changeEditingModalOpeningStatus } = useActions();

    const {
        data,
        trigger: refetch,
        isLoading,
    } = useFetch(getRealTable, {
        TypeId: activeIndex?.table ?? null,
        variant: "guide",
    });

    useEffectSkipFirstRender(() => {
        refetch();
    }, trigger);

    const generateReportName = useArchiveToReport();
    const [makeTabActive] = rtkHooks.useMakeTabActiveMutation();

    return (
        <>
            <GuideCreatingModal
                isOpen={isEditingModalStatus !== false}
                onCreateItem={(val) => {
                    updateNewItem({ ...val, Name: getGuideName(val) });
                }}
                closeOpen={() => changeEditingModalOpeningStatus(false)}
            />
            <GuideExistModal
                id={errorId}
                closeModal={cleanErrorId}
                onAcceptModal={() => {
                    getRates()
                        .then((res) => {
                            const my = res.find((el) => el.Id === errorId);
                            changeActiveIndex(
                                errorId
                                    ? {
                                          table: my?.ReportTableId ?? 0,
                                          rate: errorId,
                                          name: generateReportName(my).Name,
                                          dtos: [],
                                      }
                                    : null
                            );
                            makeTabActive({
                                type: "guide",
                                tabId: errorId ?? 0,
                            });
                            return my
                                ? generateReportName(my)
                                : {
                                      ShortName: `Архив ${errorId}`,
                                      Name: `Архивный отчет ${errorId}`,
                                      filtersState: {},
                                      Id: errorId ?? 77,
                                      GeneralReportTableId: 85,
                                  };
                        })
                        .then((data) => {
                            createNewTab(data);
                        })
                        .then(cleanErrorId);
                }}
            />

            <TableBlock
                currentTable={data}
                title={currentTableItem?.Name ?? ""}
                isLoading={isLoading}
                isBottomTableShowed={false}
            />
        </>
    );
};

export default withExcelPage(
    GuidePage,
    "guide",
    getGuideName,
    (data: { [key: string]: string | number | null }) =>
        getGuideName(data, false),
    "Внести нормативы"
);
