import React, { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";
import { Flex } from "antd";

import ModuleLink from "./ModuleLink";

type Props = {
    Icon: JSX.Element;
    className?: string;
    title: string;
    links: { name: string; link: string }[];
};

const ModuleBlock: FC<Props> = (props) => {
    const { Icon, className, title, links } = props;


    return (
        <div className={classNames(className, "animated", styles.module)}>
            <Flex gap={10} align="center">
                {React.cloneElement(Icon)}
                <h6 className={styles.module__title}>{title}</h6>
            </Flex>
            <Flex vertical gap={5} align="start">
                {links.map((el) => (
                    <ModuleLink
                        key={el.name}
                        name={el.name}
                        to={`/is-energy/${el.link}`}
                    />
                ))}
            </Flex>
        </div>
    );
};

export default ModuleBlock;
