import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { Crumb } from "entities/Breadcrumbs/components";
import { ICrumb } from "entities/Breadcrumbs/types";
import { crumbsDictionary } from "shared/constants/crumbsDictionary";
import styles from "./styles.module.css";
import { useActions, useAppSelector } from "app/hooks";
import { Button, ConfigProvider, Flex } from "antd";
import { SettingOutlined, UploadOutlined } from "@ant-design/icons";
import { exportReportRate } from "entities/ExcelTable/api";

const Breadcrumbs = () => {
    const location = useLocation();

    const { activeIndex, showHiddenValues } = useAppSelector(
        (state) => state.Excel
    );
    const { changeEditingModalOpeningStatus } = useActions();
    const [isLoadingExport, setIsLoadingExport] = useState(false);

    const crumbs = useMemo<ICrumb[]>(() => {
        const paths = location.pathname.split("/");
        if (paths.length < 3) return [];
        return paths.slice(1).map((el, id) => {
            const isActive = id === paths.length - 2;
            return {
                label:
                    (crumbsDictionary[el] ?? el) +
                    (isActive && activeIndex?.name
                        ? `: [${activeIndex.name}]`
                        : ""),
                url: paths.slice(0, id + 2).join("/"),
                isActive,
            };
        });
    }, [location.pathname, activeIndex]);

    return (
        <Flex
            gap={10}
            align="center"
            style={{ display: crumbs.length ? "flex" : "none" }}
        >
            <div className={styles.breadcrumbs}>
                {crumbs.map((el) => (
                    <Crumb style={{ height: 24 }} key={el.url} crumb={el} />
                ))}
            </div>

            <ConfigProvider
                theme={{
                    components: {
                        Button: {
                            primaryColor: "#e22739",
                            colorIconHover: "#e22739",
                            colorInfoTextHover: "#e22739",
                            defaultHoverColor: "#e22739",
                            defaultHoverBorderColor: "#e22739",
                            defaultActiveColor: "#e22739",
                            defaultActiveBorderColor: "#e22739",
                        },
                    },
                }}
            >
                {activeIndex?.name && (
                    <Flex gap={8}>
                        <Button
                            className={styles["settings-icon"]}
                            onClick={() =>
                                changeEditingModalOpeningStatus("edit")
                            }
                            icon={<SettingOutlined />}
                        />
                        <Button
                            className={styles["settings-icon"]}
                            disabled={isLoadingExport}
                            onClick={() => {
                                setIsLoadingExport(true);
                                exportReportRate(
                                    activeIndex.table ?? 0,
                                    showHiddenValues
                                ).finally(() => setIsLoadingExport(false));
                            }}
                            icon={<UploadOutlined />}
                        />
                    </Flex>
                )}
            </ConfigProvider>
        </Flex>
    );
};

export default Breadcrumbs;
