import { FC } from "react";
import { ISpItem } from "entities/Positions/types";
import { HurricaneSelect } from "shared/components";

type Props = {
    objects: ISpItem[];
    object: ISpItem | null;
    onChangeObject: (value: number | null) => void;
};

const ObjectSelect: FC<Props> = (props) => {
    const { object, onChangeObject, objects } = props;

    return (
        <HurricaneSelect
            placeholder="Выберите объект"
            onChange={onChangeObject}
            showSearch
            value={object?.Id ?? null}
            options={objects.map((option) => ({
                value: option.Id,
                label: option.Name,
            }))}
            onClear={() => {
                onChangeObject(null);
            }}
        />
    );
};

export default ObjectSelect;
