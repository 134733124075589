import { StarFilled } from "@ant-design/icons";
import { FC } from "react";
import styles from "./styles.module.css";
import classNames from "classnames";

type Props = {
    isActive: boolean;
    onClick: () => void;
};
const FavouriteStar: FC<Props> = ({ isActive, onClick }) => {
    return (
        <StarFilled
            onClick={onClick}
            className={classNames(styles.star, "animated", {
                [styles.active]: isActive,
            })}
        />
    );
};

export default FavouriteStar;
