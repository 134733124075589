import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
    ColorPallete,
    ITableCell,
    ITemplateTableDto,
    PeriodConfiguration,
    TableVariants,
} from "../types";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ICreatingFormResponse } from "entities/ISGuide/types";
import { getCreatingFormConfig } from "entities/ISGuide/api";
import {
    FilterValues,
    IndexedTablesInfo,
    IRadioFilterItem,
} from "shared/types/is-energy";
import { setDeep } from "shared/helpers";

type GuideCreatingForm = {
    form: IRadioFilterItem[];
};

type ActiveIndex = {
    table: number | null;
    rate: number;
    name: string;
    dtos: ITemplateTableDto[];
};

interface InitialState {
    promises: Record<string, Record<string, string>>;
    customReportName: string;
    filteredString: string;
    colors: ColorPallete;
    currentVariant: TableVariants;
    filterPeriodCurrent: PeriodConfiguration | null;
    activeIndex: ActiveIndex | null | undefined;
    fontSize: string;
    pointedRows: number[];
    actionCell: ITableCell | null;
    titleGenerator: (filter: {
        [key: string]: string | number | null;
    }) => string;
    showHiddenValues: boolean;
    resources: ICreatingFormResponse[];
    guideCreatingForm: GuideCreatingForm;
    isLoadingState: boolean;
    indexedTables: IndexedTablesInfo[];
    // функционал для переноса заголовка отчета в хлебные крошки
    isEditingModalStatus: false | "creating" | "edit";
}

const fontSizeFromStorage: string | null = localStorage.getItem("fontSize");

const initialColors = localStorage.getItem("colors");

const initialState: InitialState = {
    promises: {},
    customReportName: "",
    colors: initialColors
        ? JSON.parse(initialColors)
        : {
              header: "#fefe22",
              name: "#ccffcc",
              column: "#ccffcc",
              bottom: "#99ccff",
          },
    filteredString: "",
    currentVariant: "Electric",
    filterPeriodCurrent: null,
    activeIndex: undefined,
    indexedTables: [],
    fontSize: fontSizeFromStorage ?? "16",
    pointedRows: [],
    actionCell: null,
    titleGenerator: (item: { [key: string]: string | number | null }) => "",
    showHiddenValues: false,
    isLoadingState: true,
    resources: [],
    guideCreatingForm: {
        form: [],
    },
    isEditingModalStatus: false,
};

export const fetchResources = createAsyncThunk(
    "asyncGetResources",
    async (_, thunkApi) => {
        const response = await getCreatingFormConfig();

        return response;
    }
);

const excelSlice = createSlice({
    name: "excelSlice",
    initialState,
    reducers: {
        addEditing: (state, action: PayloadAction<[string, string][]>) => {
            const [key, value] = action.payload;
            state.promises = setDeep(
                action.payload,
                String(state.activeIndex?.rate),
                state.promises
            );
        },
        // довольно не тривиальная логика, стоит расписать, может улучшить: проверяем на то, что отредактируемое значение вернулось в исходное состояние. Если это так, удаляем это поле из объекта (объекта текущей таблицы). Возможен случай, что и сам объект может стать пустым - в таком случае полностью удаляем его из promises
        removeFromEditing: (state, action: PayloadAction<string>) => {
            const rate = state.activeIndex?.rate;
            if (rate) {
                const current = { ...state.promises[String(rate)] };
                delete current[action.payload];
                if (Object.values(current).length === 0) {
                    const promisesClone = { ...state.promises };
                    delete promisesClone[String(rate)];
                    state.promises = promisesClone;
                } else {
                    state.promises = {
                        ...state.promises,
                        [String(rate)]: current,
                    };
                }
            }
        },
        clearEditings: (state, action: PayloadAction<number | undefined>) => {
            if (!action.payload) {
                state.promises = {};
            } else {
                const clone = { ...state.promises };
                delete clone[String(action.payload)];
                state.promises = clone;
            }
            return state;
        },
        setFilterValue: (state, action: PayloadAction<string>) => {
            state.filteredString = action.payload;
        },
        changeColor: (
            state,
            action: PayloadAction<{ key: keyof ColorPallete; color: string }>
        ) => {
            state.colors = {
                ...state.colors,
                [action.payload.key]: action.payload.color,
            };
            localStorage.setItem("colors", JSON.stringify(state.colors));
        },
        rewriteColors: (state, action: PayloadAction<ColorPallete>) => {
            state.colors = action.payload;
            localStorage.setItem("colors", JSON.stringify(state.colors));
        },
        formPeriod: (
            state,
            action: PayloadAction<PeriodConfiguration | null>
        ) => {
            state.filterPeriodCurrent = action.payload;
        },
        changeActiveIndex: (
            state,
            action: PayloadAction<ActiveIndex | null | undefined>
        ) => {
            state.activeIndex = action.payload;
        },
        changeActiveIndexTemplateId: (state, action: PayloadAction<number>) => {
            if (!!state.activeIndex) {
                state.activeIndex.table = action.payload;
            }
        },
        setCustomName: (state, action: PayloadAction<string | undefined>) => {
            state.customReportName = action.payload ?? "";
        },
        setFontSize: (state, action: PayloadAction<string>) => {
            state.fontSize = action.payload;
            localStorage.setItem("fontSize", state.fontSize);
        },
        changeShowHiddenValuesStatus: (
            state,
            action: PayloadAction<boolean | undefined>
        ) => {
            state.showHiddenValues = action.payload ?? !state.showHiddenValues;
        },
        changeEditingModalOpeningStatus: (
            state,
            action: PayloadAction<false | "creating" | "edit">
        ) => {
            state.isEditingModalStatus = action.payload;
        },
        triggerLoader: (state, action: PayloadAction<boolean>) => {
            if (state.isLoadingState !== action.payload) {
                state.isLoadingState = action.payload;
            }
        },
        regenerateTitle: (
            state,
            action: PayloadAction<
                (filter: { [key: string]: string | number | null }) => string
            >
        ) => {
            state.titleGenerator = action.payload;
            return state;
        },
    },
    extraReducers(builder) {
        builder.addCase(fetchResources.fulfilled, (state, action) => {
            if (
                JSON.stringify(state.resources) !==
                JSON.stringify(action.payload)
            ) {
                state.resources = action.payload;

                const energyItems: FilterValues = [{ items: [] }];
                const energySourceItems: FilterValues = [];

                action.payload.forEach((el) => {
                    if (
                        energyItems[0].items.every(
                            (item) => item.value !== el.AxisCode
                        )
                    ) {
                        energyItems[0].items.push({
                            key: el.AxisName,
                            value: el.AxisCode,
                        });
                    }
                    const idx = energySourceItems.findIndex(
                        (itm) => itm.activeValue === el.AxisCode
                    );
                    if (idx !== -1) {
                        energySourceItems[idx].items.push({
                            key: el.EnergySourceName,
                            value: String(el.EnergySourceId),
                        });
                    } else {
                        energySourceItems.push({
                            items: [
                                {
                                    key: el.EnergySourceName,
                                    value: String(el.EnergySourceId),
                                },
                            ],
                            activeValue: el.AxisCode,
                        });
                    }
                });
                state.guideCreatingForm = {
                    form: [
                        { name: "Energy", items: energyItems },
                        {
                            name: "EnergySourceId",
                            dependsOnName: "Energy",
                            items: energySourceItems,
                        },
                    ],
                };
            }
            state.isLoadingState = false;
        });
        builder.addCase(fetchResources.pending, (state) => {
            state.isLoadingState = true;
        });
        builder.addCase(fetchResources.rejected, (state) => {
            state.resources = [];
            state.isLoadingState = false;
        });
    },
});

export const { actions, reducer } = excelSlice;
