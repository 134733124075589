import { FC, useEffect, useMemo, useState } from "react";
import ExcelColumnCell from "./ExcelColumnCell";
import ExcelNameCell from "./ExcelNameCell";
import { useTableBodyContext, useTableContext } from "../context";
import styles from "./styles.module.css";
import classNames from "classnames";
import { hasSubstring, sortRowsByField } from "../helpers/methods";
import { useAppSelector } from "app/hooks";
import { ITableColumn, ITableRow } from "entities/ExcelTable/types";

type Props = {
    item: ITableRow;
    columns: ITableColumn[];
    order?: number;
    none?: boolean;
    dataIndex: string;
    lineConfig?: number[];
};

const ExcelRow: FC<Props> = (props) => {
    const { item, columns, order, none, dataIndex, lineConfig } = props;

    const hasChildren = !!item.Children?.length;

    const { sorted, activeTable } = useTableContext();
    const { actualEditable, isBottomTableShowed } = useTableBodyContext();

    const { filteredString } = useAppSelector((state) => state.Excel);

    const isRealNone = useMemo(() => {
        if (none) return true;
        return item.IsSummaryRow && !isBottomTableShowed;
    }, [none, isBottomTableShowed, item]);

    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (isRealNone && isOpen) {
            setIsOpen(false);
        }
    }, [isRealNone]);

    useEffect(() => {
        if (activeTable?.openedRows?.includes(item.PositionId)) {
            setIsOpen(true);
        } else if (activeTable?.openedRows) {
            setIsOpen(false);
        }
    }, [activeTable?.openedRows, item.PositionId]);

    const hasFilter = useMemo(() => {
        return (
            filteredString.length === 0 || hasSubstring(item, filteredString)
        );
    }, [filteredString]);

    const lineArr = useMemo<number[]>(() => {
        const arr = lineConfig?.length ? [...lineConfig] : [];
        if (isOpen) arr.push(order ?? 0);
        return arr;
    }, [lineConfig, isOpen]);

    useEffect(() => {
        if (actualEditable?.includes(dataIndex.split(":")[0] + "."))
            setIsOpen(true);
    }, [actualEditable]);

    const sortedRows = useMemo<ITableRow[]>(() => {
        if (!item.Children?.length) return [];
        return sortRowsByField([...item.Children], sorted);
    }, [sorted, item]);

    const isPicked = useMemo<boolean>(() => {
        if (!activeTable?.pickedRows.length) return false;
        return activeTable.pickedRows.includes(item.PositionId);
    }, [item, activeTable?.pickedRows]);

    return (
        <>
            <tr
                data-row={dataIndex}
                data-none={none}
                data-id={item.Id}
                data-status={
                    isRealNone ? "none" : hasChildren ? "parent" : "child"
                }
                data-type={item.IsSummaryRow ? "summary" : "default"}
                className={classNames(styles["excel-row"], {
                    [styles.none]: none || !hasFilter,
                    [styles.bold]: hasChildren,
                })}
            >
                {!isRealNone && (
                    <>
                        <ExcelNameCell
                            dataIndex={`${dataIndex}:${columns[0].Id}`}
                            order={order}
                            row={item}
                            columnId={columns[0].Id}
                            isPicked={isPicked}
                            isOpen={isOpen}
                            isSummary={!!item.IsSummaryRow}
                            lineConfig={lineArr}
                            setOpen={() => setIsOpen((prev) => !prev)}
                        />
                        {columns.slice(1).map((col, id) => (
                            <ExcelColumnCell
                                dataIndex={`${dataIndex}:${col.Id}`}
                                key={id}
                                isPicked={isPicked}
                                blocked={hasChildren}
                                ariaColindex={id}
                                subColumns={col.Children}
                                item={item}
                            />
                        ))}
                    </>
                )}
            </tr>
            {!!sortedRows.length &&
                sortedRows.map((child, id) => (
                    <ExcelRow
                        dataIndex={`${dataIndex}.${id + 1}`}
                        key={id}
                        lineConfig={lineArr}
                        order={(order || 0) + 1}
                        item={child}
                        columns={columns}
                        none={!isOpen}
                    />
                ))}
        </>
    );
};

export default ExcelRow;
