import { useMemo, createElement, useRef, FC, useState } from "react";
import "./styles.scss";
import LogoSvg from "../Logo/logo";
import { useLocation, useNavigate } from "react-router-dom";
import { LogoutOutlined } from "@ant-design/icons";
import { Flex, Popover, Tooltip, Typography } from "antd";
import { logout } from "services/login/loginSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Menu, User, UserCog } from "lucide-react";
import { IRoute } from "shared/types/routes";
import Breadcrumbs from "widgets/Breadcrumbs";
import { RouteTab, Slider, SpinnerUserIcon } from "shared/components";
import { RealRoute, RouteModule } from "shared/types";
import { secretKey } from "shared/constants/common";
import simpleEncryptDecrypt from "pages/PointEventsPage/simpleEncryptDecrypt";
import { IsEnergyCrumb } from "entities/Breadcrumbs/components";

// window.location.pathname;
const regExp = /^\/(login)?$/g;
const originalText =
    `${localStorage.getItem("username")}` +
    `&${localStorage.getItem("password")}`;

type Props = {
    realRoutes: RealRoute;
};

type TitleProps = {
    title: string;
};

type TabsProps = {
    actualRoutes: IRoute[];
};

const Header: FC<Props> = ({ realRoutes }) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const hidden = useMemo<boolean>(
        () => regExp.test(location.pathname),
        [location.pathname]
    );
    const { currentUser } = useAppSelector((state) => state.Login);
    const { links } = useAppSelector((state) => state.IsEnergy);

    const onExit = () => {
        localStorage.clear();
        dispatch(logout());
    };

    const moduleConfig = useMemo(() => {
        return getModule(location.pathname.split("/")[1]);
    }, [location.pathname]);

    const modulator = useMemo(() => {
        const actualRoutes =
            moduleConfig === "is_energy"
                ? []
                : // ? ISEnergyRoutes
                  realRoutes.actualRoutes.filter(
                      (el) => el.module === moduleConfig
                  );

        return { actualRoutes, moduleName: modules[moduleConfig] };
    }, [moduleConfig, realRoutes]);

    const { moduleName, actualRoutes } = modulator;

    const toggleOpen = () => {
        setIsOpen((prev) => !prev);
    };

    if (hidden) return null;
    return (
        <div className="header">
            <div className="header-container">
                <Flex align="flex-end" gap={16}>
                    <Popover
                        content={<BurgerMenu closePopover={toggleOpen} />}
                        open={isOpen}
                        style={{ padding: 0 }}
                    >
                        <div className="logo-container" onClick={toggleOpen}>
                            <Menu fontSize={24} />

                            <LogoSvg />
                        </div>
                    </Popover>
                    <ModuleTitle title={moduleName} />
                </Flex>
                {moduleConfig === "is_energy" && (
                    <Flex className="favourites">
                        {links.map((el, id) => (
                            <IsEnergyCrumb
                                key={el.link}
                                link={el}
                                pathname={location.pathname}
                                isLast={id === links.length - 1}
                            />
                        ))}
                    </Flex>
                )}

                <Flex align="flex-end" style={{ marginLeft: "auto" }}>
                    <SpinnerUserIcon>
                        {createElement(currentUser?.IsMaster ? UserCog : User, {
                            color: "black",
                            fontSize: 18,
                            style: { marginRight: 8 },
                        })}
                    </SpinnerUserIcon>

                    {localStorage.getItem("username")}
                    <Tooltip title="Выйти" placement="left">
                        <i className="exit-icon" onClick={onExit}>
                            <LogoutOutlined style={{ color: "black" }} />
                        </i>
                    </Tooltip>
                </Flex>
            </div>

            <ModuleTabs actualRoutes={actualRoutes} />

            <Breadcrumbs />
        </div>
    );
};

type BurgerProps = {
    closePopover: () => void;
};
export const BurgerMenu: FC<BurgerProps> = ({ closePopover }) => {
    const navigate = useNavigate();
    const { currentUserStatus: status } = useAppSelector(
        (state) => state.Login
    );

    if (status !== "fulfilled") return null;
    return (
        <div className="burger-menu-container">
            <ul>
                {[
                    { path: "/point-events-lineman", name: "События" },
                    { path: "/is-energy", name: "ИС-Энергия" },
                    {
                        path: `https://${window.location.hostname.replace(
                            "aux-",
                            ""
                        )}/login.html#${simpleEncryptDecrypt(
                            originalText + "Infinity" + document.cookie,
                            secretKey
                        )}`,
                        // path: `https://lvnp.enrsoft.ru?#${simpleEncryptDecrypt(
                        //     originalText + "Infinity" + document.cookie,
                        //     secretKey
                        // )}`,
                        name: "Почасовое планирование",
                    },
                ].map((link, id) => (
                    <li
                        onClick={() => {
                            if (link.path) {
                                link.path.includes("https")
                                    ? (window.location.href = link.path)
                                    : navigate(link.path.replaceAll("*", ""));
                            }
                            closePopover();
                        }}
                        key={id}
                    >
                        {link.name}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const modules: Record<RouteModule, string> = {
    is_energy: "ИС-Энергия",
    events: "События",
};

function getModule(location: string): RouteModule {
    switch (location) {
        case "is-energy":
            return "is_energy";
        default:
            return "events";
    }
}

const ModuleTitle: FC<TitleProps> = ({ title }) => {
    return (
        <Flex vertical gap={8}>
            <Typography.Text className="header__module">
                КИС УТЭР Модуль {title}
            </Typography.Text>
        </Flex>
    );
};

const ModuleTabs: FC<TabsProps> = ({ actualRoutes }) => {
    const ref = useRef(null);

    if (!actualRoutes?.length) return null;
    return (
        <Slider
            ref={ref}
            className="header__slider"
            step={198}
            baseValues={actualRoutes.length}
            marginRight={140}
        >
            <Flex>
                {actualRoutes.map((el) => (
                    <RouteTab path={el.path} name={el.name} key={el.path} />
                ))}
            </Flex>
        </Slider>
    );
};

export default Header;
