import { FC, useMemo } from "react";
import styles from "./styles.module.css";
import ExcelColumnItem from "./ExcelColumnItem";
import classNames from "classnames";
import { ITableCell, ITableColumn, ITableRow } from "entities/ExcelTable/types";
import { useAppSelector } from "app/hooks";
import { darkenColor } from "shared/helpers";

type Props = {
    ariaColindex?: number;
    subColumns?: ITableColumn[];
    dataIndex: string;
    blocked?: boolean;
    isPicked: boolean;
    item: ITableRow;
};

function takeCellItemByColumn(
    data: number,
    value: ITableCell[]
): ITableCell | undefined {
    const current = value.find(({ ColumnIds }) => ColumnIds.includes(data));
    if (!current) return undefined;
    return current;
}

const ExcelColumnCell: FC<Props> = (props) => {
    const {
        ariaColindex,
        subColumns,
        dataIndex,
        blocked,
        isPicked,
        item: { Cells: value, IsSummaryRow: isSummary, PositionId: rowId },
    } = props;

    const {
        showHiddenValues,
        colors: { column: columnColor, bottom: bottomColumn },
    } = useAppSelector((state) => state.Excel);

    const showedSubColumns = useMemo(() => {
        if (!subColumns) return [];
        if (showHiddenValues) return subColumns;
        return subColumns.filter(({ IsHidden }) => !IsHidden);
    }, [subColumns, showHiddenValues]);

    const actualBackgroundColor = useMemo<string>(
        () => (isSummary ? bottomColumn : columnColor),
        [isSummary, bottomColumn, columnColor]
    );

    if (!showedSubColumns.length) {
        const current = takeCellItemByColumn(
            +dataIndex.split(":").at(-1)!,
            value
        );

        const blockModified = current !== undefined && current.IsMody !== true;
        const currentColor = blockModified
            ? actualBackgroundColor
            : darkenColor(actualBackgroundColor, 10);

        return (
            <td
                aria-colindex={ariaColindex}
                data-id={dataIndex}
                className={classNames(styles["excel-td"])}
                style={{
                    backgroundColor: isPicked ? "orange" : currentColor,
                }}
            >
                <ExcelColumnItem
                    dataIndex={dataIndex}
                    blockModified={blockModified}
                    rowId={rowId}
                    blocked={blocked}
                    value={current}
                />
            </td>
        );
    }

    return (
        <>
            {showedSubColumns.map((column, id) => {
                const current = takeCellItemByColumn(column.Id, value);
                const blockModified =
                    current !== undefined && current.IsMody !== true;

                const currentColor = blockModified
                    ? actualBackgroundColor
                    : darkenColor(actualBackgroundColor, 10);
                return (
                    <td
                        key={column.Id}
                        data-id={`${dataIndex}.${id}`}
                        aria-colindex={ariaColindex}
                        className={classNames(styles["excel-td"])}
                        style={{
                            backgroundColor: isPicked ? "orange" : currentColor,
                        }}
                    >
                        <ExcelColumnItem
                            key={id}
                            blockModified={blockModified}
                            rowId={rowId}
                            dataIndex={`${dataIndex}.${id}`}
                            canHidden={
                                typeof column === "object" && "hidden" in column
                            }
                            blocked={blocked}
                            value={current}
                        />
                    </td>
                );
            })}
        </>
    );
};

export default ExcelColumnCell;
