import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload } from "antd";
import { RcFile } from "antd/es/upload";
import { FC } from "react";

type Props = {
    title?: string;
    disabled?: boolean;
    fileList?: RcFile[];
    modifiedFileList?: (files: RcFile[]) => void | false;
};
const HurricaneFile: FC<Props> = (props) => {
    const { title, fileList, modifiedFileList, disabled } = props;

    return (
        <Upload
            disabled={disabled}
            multiple={false}
            fileList={fileList}
            onRemove={() => modifiedFileList?.([])}
            beforeUpload={(file) => {modifiedFileList?.([file]); return false;}}
        >
            <Button style={{ minHeight: 40 }} icon={<UploadOutlined />}>
                {title ?? "Выберите файл"}
            </Button>
        </Upload>
    );
};

export default HurricaneFile;
