import { FC, createElement, useMemo } from "react";
import styles from "./style.module.css";
import classNames from "classnames";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { useActions, useAppSelector } from "app/hooks";
import { TabsItem } from "entities/ExcelTable/types";

type Props = {
    onCreateItem?: () => void;
    openModal: (val: { val: number; table: number }) => void;
    item: TabsItem;
    isEditingMode?: boolean;
};

const ExcelTab: FC<Props> = (props) => {
    const {
        item: { ShortName, Id, ReportTableId, Name, ReportTemplateTableDtos },
        onCreateItem,
        openModal,
        isEditingMode,
    } = props;

    const { activeIndex, promises } = useAppSelector((state) => state.Excel);
    const { changeActiveIndex } = useActions();

    const isActive = Id == activeIndex?.rate;
    const isNewItem = onCreateItem !== undefined;

    const isInPromises = useMemo<boolean>(
        () => Object.keys(promises).includes(String(Id)),
        [promises, Id]
    );

    const actualName = useMemo<string>(() => {
        if (!Name && !ShortName) return 'Без имени'
        if ((Name.length && Name.length <= 20) || !ShortName) return Name;
        return ShortName;
    }, [Name, ShortName]);

    return (
        <div
            onClick={() => {
                if (isNewItem) {
                    onCreateItem();
                } else {
                    changeActiveIndex({
                        rate: Id,
                        table:
                            ReportTableId ??
                            ReportTemplateTableDtos?.find((el) => el.IsActive)
                                ?.ReportTableId ??
                            0,
                        name: Name,
                        dtos: ReportTemplateTableDtos ?? [],
                    });
                }
            }}
            className={classNames(styles["tab"], {
                [styles.active]: isActive,
                [styles.new]: isNewItem,
                [styles.editing]: isInPromises,
                [styles.editMode]: isEditingMode,
            })}
        >
            <span>{actualName}</span>
            {createElement(isNewItem ? PlusOutlined : CloseOutlined, {
                className: styles["tab__icon"],
                onClick: (e) => {
                    e.stopPropagation();
                    if (isNewItem) {
                        onCreateItem();
                    } else {
                        openModal({
                            val: Id,
                            table:
                                ReportTableId ??
                                ReportTemplateTableDtos?.find(
                                    (el) => el.IsActive
                                )?.ReportTableId ??
                                0,
                        });
                    }
                },
            })}
        </div>
    );
};

export default ExcelTab;
