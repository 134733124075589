import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import styles from "./style.module.css";
import ExcelHeaderCell from "./ExcelHeaderCell";
import ExcelHeaderNameCell from "./ExcelHeaderNameCell";
import { ITableColumn } from "entities/ExcelTable/types";
import ExcelHeaderSubCell from "./ExcelHeaderSubCell";
import { useAppSelector } from "app/hooks";

type Props = {
    columns: ITableColumn[];
    indexes: number[];
};

const ExcelHeader = forwardRef<HTMLTableSectionElement, Props>((props, ref) => {
    const { columns, ...rest } = props;

    const {
        showHiddenValues,
        fontSize,
        colors: { header: headerColor },
    } = useAppSelector((state) => state.Excel);

    const subColumns = useMemo<ITableColumn[]>(() => {
        return columns.reduce<ITableColumn[]>(
            (acc: ITableColumn[], cur: ITableColumn) => {
                if (!cur.Children?.length) return acc;
                const filteredChildren = cur.Children.filter(
                    ({ IsHidden }) => !IsHidden || showHiddenValues
                );
                return [...acc, ...filteredChildren];
            },
            []
        );
    }, [columns, showHiddenValues]);

    const sliced = columns.slice(1);
    const draggable = useRef<string | null>(null);
    const [draggableTrigger, setDraggableTrigger] = useState<boolean>(false);

    function onChangeDraggable(id: string | null) {
        draggable.current = id;
        setDraggableTrigger(true);
    }

    function removeDraggable() {
        setDraggableTrigger(false);
        draggable.current = null;
    }

    useEffect(() => {
        document.addEventListener("mouseup", removeDraggable);

        return () => {
            document.removeEventListener("mouseup", removeDraggable);
        };
    }, []);

    return (
        <thead
            ref={ref}
            className={styles["excel-head"]}
            style={{ fontSize: fontSize + "px" }}
        >
            <tr
                className={styles["excel-header"]}
                data-status="parent"
                style={{ backgroundColor: headerColor }}
            >
                <ExcelHeaderNameCell
                    column={columns[0]}
                    expanded={!!subColumns.length}
                    {...rest}
                />
                {sliced.map((el, index) => (
                    <ExcelHeaderCell
                        key={el.Id}
                        columnsLength={sliced.length}
                        expanded={!!(!el.Children && subColumns.length)}
                        ariaColindex={index + 1}
                        draggable={draggable}
                        onChangeDraggable={onChangeDraggable}
                        column={el}
                        draggableTrigger={draggableTrigger}
                    />
                ))}
            </tr>
            {!!subColumns.length && (
                <tr
                    className={styles["excel-header"]}
                    data-status="parent"
                    style={{ backgroundColor: headerColor }}
                >
                    {subColumns.map((el) => (
                        <ExcelHeaderSubCell
                            key={el.Id}
                            isHidden={false}
                            column={el}
                        />
                    ))}
                </tr>
            )}
        </thead>
    );
});

export default ExcelHeader;
