import classNames from "classnames";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { Flex } from "antd";
import { FavouriteStar } from "shared/components";
import { useActions, useAppSelector } from "app/hooks";

type Props = {
    to: string;
    name: string;
};
const ModuleLink: FC<Props> = (props) => {
    const { to, name } = props;

    const { toggleLink } = useActions();
    const { links: storeLinks } = useAppSelector((state) => state.IsEnergy);

    const isActive = useMemo(() => {
        return storeLinks.some(el => el.link === to)
    }, [storeLinks, to])

    return (
        <Flex gap={10} align="center">
            <Link
                className={classNames("animated", styles.module__link)}
                to={to}
            >
                {name}
            </Link>

            <FavouriteStar isActive={isActive} onClick={() => toggleLink({ name, link: to })} />
        </Flex>
    );
};

export default ModuleLink;
