import { RefObject, useEffect, useRef, useState } from "react";
import { CellDirection } from "shared/types";

type ReturnType = {
    actualEditable: string | null;
    updateEditable: (id?: string) => void;
    increaseEditableCell: () => void;
};

export const useEditableCell = (
    table: RefObject<HTMLTableElement>,
): ReturnType => {
    function updateEditable(id?: string) {
        actualEditableSync.current = id || null;
        setActualEditable(id || null);
    }

    function horizontalIncreaseCell(
        direction: "left" | "right",
        element: Element,
        row: HTMLTableRowElement
    ) {
        const closestTd = element.closest("td");
        if (!closestTd) {
            updateEditable(undefined);
            return;
        }
        const array = Array.from(row.children);
        if (direction === "left") {
            array.reverse();
        }
        const index = array.indexOf(closestTd);
        if (index < array.length) {
            const next = array[index + 1] as HTMLTableCellElement;
            updateEditable(next.dataset.id);
        }
    }

    function increaseEditableCell(direction: CellDirection = "bottom") {
        if (!actualEditable) return;
        const element = document.querySelector(`[data-id="${actualEditable}"]`);
        if (!element) return;

        const [rowInfo, columnInfo] = actualEditable.split(":");
        const row = element.closest("tr") as HTMLTableRowElement;

        if (["left", "right"].includes(direction)) {
            horizontalIncreaseCell(direction as "left" | "right", element, row);
            return;
        }
        let array = Array.from(table.current?.children || []);
        if (!array.length) return;
        array = Array.from(array.at(-1)?.children || []).filter(
            (el) => (el as HTMLTableRowElement).dataset.status !== "none"
        );
        if (direction === "top") {
            array.reverse();
        }
        const index = array.indexOf(row) + 1;
        if (index <= array.length - 1) {
            const elem = array[index] as HTMLTableRowElement;
            updateEditable(
                [
                    (array[index] as HTMLTableRowElement).dataset.row || "",
                    columnInfo,
                ].join(":")
            );
        } else {
            // updateEditable(undefined);
        }
    }

    const [actualEditable, setActualEditable] = useState<string | null>(null);
    const actualEditableSync = useRef<string | null>(null);

    function onClickTable(e: MouseEvent) {
        if (!actualEditableSync.current) return;
        const cell = (e.target as HTMLElement).closest(
            "[data-id]"
        ) as HTMLElement;
        if (!cell || cell.dataset.id !== actualEditableSync.current) {
            updateEditable();
        }
    }

    // useEffect(() => {
    //     document.addEventListener("click", onClickTable);
    //     return () => {
    //         document.removeEventListener("click", onClickTable);
    //     };
    // }, []);

    return {
        actualEditable,
        updateEditable,
        increaseEditableCell,
    };
};
