import { Flex, Modal } from "antd";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { TabsItem, TabsListProps } from "entities/ExcelTable/types";
import Tab from "./Tab";
import { useActions, useAppSelector } from "app/hooks";
import { DefaultModalFooter, Slider } from "shared/components";
import { rtkHooks } from "app/store";
import { useIndexedContext } from "processes/indexedDBContext";

const TabsList: FC<TabsListProps> = (props) => {
    const { items, onCreateItem, createTabTitle, type } = props;

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { clearEditings } = useActions();
    const { activeIndex, promises, isEditingModalStatus } = useAppSelector(
        (state) => state.Excel
    );

    const { deleteIndexedTable } = useIndexedContext();

    const onCloseOpen = () => {
        setCurId(null);
        setIsOpen(false);
    };
    const cleanCurrentindex = () => {
        if (activeIndex?.rate === curId?.val) {
            const current =
                items.find((el) => el.Id !== curId?.val) ??
                items[0] ??
                undefined;
        }
        onCloseOpen();
    };

    useEffect(() => {
        if (!items.find((el) => el.Id === curId?.val)) {
            setCurId(null);
        }
    }, [items]);

    const [curId, setCurId] = useState<{ val: number; table: number } | null>(
        null
    );

    const currentItem = useMemo<TabsItem | null>(() => {
        const item = items.find((el) => el.Id === curId?.val);
        if (!item) return items?.[0] ?? null;
        return item;
    }, [curId, items]);

    const ref = useRef();

    useEffect(() => {
        if (ref.current) {
            // @ts-ignore
            ref.current.calculateWidth();
        }
    }, [items]);

    const isInPromises = useMemo<boolean>(
        () => Object.keys(promises).includes(String(curId)),
        [promises, curId]
    );

    const [removeTab] = rtkHooks.useRemoveTabMutation();

    return (
        <>
            <Modal
                open={isOpen}
                title="Перенести в архив"
                centered
                width={900}
                onCancel={onCloseOpen}
                footer={() => (
                    <DefaultModalFooter
                        onAccept={() => {
                            cleanCurrentindex();
                            clearEditings(curId?.val ?? undefined);
                            removeTab({ type, tabId: curId?.val ?? 0 });
                            if (curId) deleteIndexedTable(curId.table);
                        }}
                        onReject={onCloseOpen}
                    />
                )}
            >
                {isInPromises
                    ? `Отчет "${
                          currentItem?.ShortName ?? currentItem?.Name
                      }" имеет измененные ячейки. Примите изменения или отмените их перед удалением`
                    : `Вы действительно хотите перенести в архив отчет "${
                          currentItem?.ShortName ?? currentItem?.Name
                      }"?
                 Перед архивированием рекомендуем сохранить все изменения`}
            </Modal>
            <Flex gap={15} align="center">
                <Slider ref={ref} step={265} marginRight={500}>
                    <Flex gap={13} align="center" className="tab-list">
                        {items.map((item) => (
                            <Tab
                                item={item}
                                isEditingMode={isEditingModalStatus === "edit"}
                                key={item.Id}
                                openModal={(val: {
                                    val: number;
                                    table: number;
                                }) => {
                                    setCurId(val);
                                    setIsOpen(true);
                                }}
                            />
                        ))}
                    </Flex>
                </Slider>

                <Tab
                    item={{
                        ShortName: createTabTitle,
                        Name: "",
                        Id: Infinity,
                        ReportTableId: Infinity,
                    }}
                    openModal={() => {}}
                    onCreateItem={onCreateItem}
                />
            </Flex>
        </>
    );
};

export default TabsList;
