import { ApiModule } from "shared/ApiModule";

export const uploadData = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file, file.name);
    const result = await ApiModule.post<undefined, FormData>(
        "/PnosFileUploading/terPlan",
        formData
    );
};
