import React, { FC, RefObject, useEffect, useMemo, useRef } from "react";
import styles from "./style.module.css";
import { useTableContext } from "../context";
import classNames from "classnames";
import { ITableColumn } from "entities/ExcelTable/types";
import { withSortingClass } from "hocs/withSortingClass";
import { useAppSelector } from "app/hooks";

type Props = {
    column: ITableColumn;
    ariaColindex?: number;
    columnsLength: number;
    draggable: RefObject<string | null>;
    onChangeDraggable: (id: string | null) => void;
    draggableTrigger: boolean;
    expanded: boolean;
};

const dataIndexRegExp = /^(row-[0-9.]+):([a-zA-z]+)\..+$/g;

const ExcelHeaderCell: FC<Props & { sortingClass: string }> = (props) => {
    const {
        column,
        ariaColindex,
        columnsLength,
        sortingClass,
        draggable,
        expanded,
        draggableTrigger,
        onChangeDraggable,
    } = props;
    const { Name: name, Children: children } = column;
    const uniqName = useMemo(() => String(column.Id), [column]);

    const { currentColumn, changeColumnsPositions, toggleSorting } =
        useTableContext();

    const dragStartHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        const cell = e.target as HTMLTableCellElement;
        if (cell.dataset.column) currentColumn.current = uniqName;
    };

    const dragEndHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        const cell = e.currentTarget as HTMLTableCellElement;
        cell.style.opacity = "1";
    };

    const dragOverHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        const cell = e.currentTarget as HTMLTableCellElement;
        cell.style.opacity = "0.5";
    };

    const dropHandler = (e: React.DragEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        const cell = e.currentTarget as HTMLTableCellElement;
        cell.style.opacity = "1";
        changeColumnsPositions(cell);
        // currentColumn.current = "";
    };

    const { showHiddenValues } = useAppSelector((state) => state.Excel);

    const colSpan = useMemo<number>(() => {
        if (!children) return 1;
        if (showHiddenValues) return children.length;
        return children.filter((el) => !el.IsHidden).length;
    }, [children, showHiddenValues]);

    const hasNotSubcolumns = !children?.length;

    const partsName = useMemo(() => {
        return name.split(`\n`);
    }, [name]);

    const ref = useRef<HTMLDivElement>(null);

    const onMouseMove = (e: MouseEvent) => {
        if (draggable.current === uniqName && ref.current) {
            ref.current.style.minWidth =
                e.clientX - ref.current.getBoundingClientRect().left + "px";
        }
    };

    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        return () => {
            document.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    const isMaximize = useMemo<boolean>(
        () => columnsLength <= 4,
        [columnsLength]
    );

    return (
        <>
            <th
                draggable={!draggableTrigger}
                onDragStart={dragStartHandler}
                onDragLeave={dragEndHandler}
                onDragEnd={dragEndHandler}
                onDragOver={dragOverHandler}
                onDrop={dropHandler}
                data-column={uniqName}
                aria-colindex={ariaColindex}
                className={styles["excel-header__element"]}
                colSpan={colSpan}
                rowSpan={expanded ? 2 : 1}
            >
                <div
                    ref={ref}
                    style={
                        isMaximize
                            ? {}
                            : { minWidth: `calc((100vw - 90px) * 0.1 - 2.3px)` }
                    }
                    onClick={() => {
                        if (hasNotSubcolumns) {
                            toggleSorting(`Cell-${column.Id}`);
                        }
                    }}
                    className={classNames(
                        styles["excel-header__cell"],
                        styles[sortingClass],
                        {
                            [styles.alone]: hasNotSubcolumns,
                            [styles.maximize]: isMaximize,
                        }
                    )}
                >
                    {partsName.map((part, id) => (
                        <span key={id}>{part}</span>
                    ))}
                </div>
                {!isMaximize && (
                    <div
                        className={styles.resizer}
                        onMouseDown={() => onChangeDraggable(uniqName)}
                    />
                )}
            </th>
        </>
    );
};

export default withSortingClass(ExcelHeaderCell);
