import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { parseJson } from "shared/helpers";

type Link = { name: string; link: string };

interface InitialState {
    links: Link[];
}

const initialState: InitialState = {
    links: [],
};

const isEnergySlice = createSlice({
    name: "is-energySlice",
    initialState,
    reducers: {
        getFromLocalStorage: (state) => {
            const links = localStorage.getItem("links");
            if (links) {
                state.links = parseJson<Link[]>(links);
            }
        },
        toggleLink: (state, action: PayloadAction<Link>) => {
            const current = state.links.find(
                (link) => link.name === action.payload.name
            );
            if (!current) {
                state.links = [...state.links, action.payload];
            } else {
                state.links = state.links.filter(
                    (el) => el.link !== current.link
                );
            }
            localStorage.setItem("links", JSON.stringify(state.links));
        },
    },
});

export const { actions, reducer } = isEnergySlice;
