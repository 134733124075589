import { FC, useCallback, useRef, useState } from "react";
import styles from "./style.module.css";
import classNames from "classnames";
import { ITableColumn } from "entities/ExcelTable/types";
import { Settings } from "lucide-react";
import { SettingsContent } from "entities/ExcelTable/components";
import { useColorSettings } from "ExcelTable/helpers/hooks";
import { useEffectSkipFirstRender } from "hooks";
import { useAppSelector } from "app/hooks";
import { useIndexedContext } from "processes/indexedDBContext";
import { useTableContext } from "ExcelTable/context";

type Props = {
    column: ITableColumn;
    expanded: boolean;
    indexes: number[];
};

const ExcelHeaderNameCell: FC<Props> = ({
    column,

    expanded,
    indexes,
}) => {
    const { Name: name } = column;

    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);
    const { colors: stateColors } = useAppSelector((state) => state.Excel);

    const [colors, changeColor, rewriteColors] = useColorSettings();
    const { updateIndexedTable } = useIndexedContext();
    const { activeTable } = useTableContext();

    useEffectSkipFirstRender(() => {
        if (!isOpen) {
            rewriteColors(stateColors);
        }
    }, isOpen);

    const ref = useRef<HTMLDivElement>(null);

    const draggable = useRef(false);

    const onPlusClick = () => {
        if (activeTable) {
            updateIndexedTable(activeTable.tableId, {
                openedRows: indexes,
            });
        }
    };
    const onMinusClick = () => {
        // setAllClose((prev) => !prev);
        if (activeTable) {
            updateIndexedTable(activeTable.tableId, {
                openedRows: [],
            });
        }
    };

    return (
        <>
            <th
                aria-colindex={0}
                className={classNames(styles["excel-name-cell-container"])}
                rowSpan={expanded ? 2 : 1}
            >
                <Settings
                    fontSize={14}
                    width={18}
                    height={18}
                    onClick={() => setIsOpen(true)}
                    className={styles["excel-name-cell__settings"]}
                />

                <SettingsContent
                    colors={colors}
                    changeColor={changeColor}
                    isOpen={isOpen}
                    onClose={closeModal}
                />

                {/* <div
                    className={styles.resizer}
                    onMouseDown={() => (draggable.current = true)}
                /> */}
                <div ref={ref} className={styles["excel-header__cell"]}>
                    <span onClick={onPlusClick} className={styles.plus}>
                        +
                    </span>
                    {name}
                    <span onClick={onMinusClick} className={styles.minus}>
                        -
                    </span>
                </div>
            </th>
        </>
    );
};

export default ExcelHeaderNameCell;
