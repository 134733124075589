import { FC, useMemo } from "react";
import classNames from "classnames";
import styles from "./style.module.css";
import { useTableContext } from "../context";
import { ITableColumn } from "entities/ExcelTable/types";
import { withSortingClass } from "hocs/withSortingClass";
import { useAppSelector } from "app/hooks";
import { Flex } from "antd";

type Props = {
    column: Required<ITableColumn>["Children"][0];
    isHidden: boolean;
};

const ExcelHeaderSubCell: FC<Props & { sortingClass: string }> = (props) => {
    const { column, isHidden, sortingClass } = props;
    const name = `Cell-${column.Id}`;

    const { toggleSorting } = useTableContext();

    const { fontSize } = useAppSelector((state) => state.Excel);

    const partsName = useMemo(() => {
        console.log(column.Name.includes(`\n`))
        return column.Name.split(`\n`);
    }, [column.Name]);

    return (
        <th
            style={{ fontSize: fontSize + "px" }}
            className={classNames(
                styles["excel-sub-row__item"],
                styles[sortingClass],
                {
                    [styles.none]:
                        isHidden && "hidden" in column && column.hidden,
                }
            )}
            onClick={() => toggleSorting(name)}
        >
            <Flex vertical align="center" gap={7}>
                {partsName.map((part, id) => (
                    <span key={id}>{part}</span>
                ))}
            </Flex>
        </th>
    );
};

export default withSortingClass<Props>(ExcelHeaderSubCell);
