import { useAppSelector } from "app/hooks";
import React, { FC, useLayoutEffect, useState } from "react";
import { HurricaneSelect } from "shared/components";
import { REPORT_TYPE_DICTIONARY } from "shared/constants/is-energy";
import { MakeTableActiveParams } from "shared/types/is-energy";

const data = Object.entries(REPORT_TYPE_DICTIONARY).map(([value, key]) => ({
    label: key,
    value,
}));

type Props = {
    isLoading: boolean;
    makeTableActive: (params: MakeTableActiveParams) => void;
};

const TableTypeSelect: FC<Props> = React.memo((props) => {

    const { activeIndex } = useAppSelector((state) => state.Excel);
    const { makeTableActive, isLoading } = props;

    function onMakingTableActive(type: string) {
        if (type !== activeType) {
            setActiveType(type);
            if (activeIndex) {
                const actualTable = activeIndex.dtos.find(
                    (el) => el.ReportTemplateTableType === type
                );
                makeTableActive({
                    tableType: type,
                    reportTemplateId: activeIndex.rate,
                    needCreatingBefore: !actualTable,
                    tableId: actualTable?.Id ?? undefined,
                });
            }
        }
    }

    const [activeType, setActiveType] = useState<string>(data[0].value);
    useLayoutEffect(() => {
        if (!activeIndex) return setActiveType(data[0].value);
        return setActiveType(
            activeIndex.dtos.find((el) => el.IsActive)
                ?.ReportTemplateTableType ?? data[0].value
        );
    }, [activeIndex]);

    return (
        <HurricaneSelect
            minWidth={220}
            label={
                !!activeIndex
                    ? "Тип отчета"
                    : "Тип отчета (требуется активный отчет)"
            }
            disabled={!activeIndex || isLoading}
            value={activeType}
            onChange={onMakingTableActive}
            options={data}
        />
    );
});

export default TableTypeSelect;
