import { urlApi } from "app/axiosInstance";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "helpers/getAuth";
import { BalanceReport } from "../types";

const auth = getAuth();

function updateBalance(params: Record<string, string>) {
    const filter: Record<string, string> = {
        energySource: params?.EnergySourceId ?? "",
    };
    ["month", "year"].forEach((el) => {
        if (params?.[el]) {
            filter[el] = params[el];
        }
    });
    return filter;
}

export const balanceApi = createApi({
    reducerPath: "balance",
    baseQuery: fetchBaseQuery({
        baseUrl: urlApi + "/rest/ReportTemplate",
        prepareHeaders: (headers) => {
            if (auth?.headers?.Authorization) {
                headers.set(
                    "Authorization",
                    `Basic ` + auth.headers.Authorization
                );
            }
            return headers;
        },
    }),

    tagTypes: ["Balance"],
    endpoints: (builder) => ({
        getReportBalanceList: builder.query<BalanceReport[], any>({
            query: (params: Record<string, string>) => ({
                url: "filter",
                params: updateBalance(params),
            }),
        }),

        // deleteUser: builder.mutation<IUser, number>({
        //     query: (query: number) => ({
        //         url: `/users?id=${query}`,
        //         ...auth,
        //         headers: {
        //             Authorization: `Basic ${auth.headers.Authorization}`,
        //             "Content-Type": "application/json",
        //         },
        //         method: "DELETE",
        //     }),
        //     invalidatesTags: ["Users"],
        // }),
    }),
});
