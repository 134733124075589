import { ConfigProvider, Flex, Table } from "antd";
import { FC, useEffect, useMemo, useState } from "react";
import ArchiveFilters from "../ArchiveFilters";
import { getColumnsApiDictionary } from "entities/ExcelTable/helpers/archive.constants";
import { ColumnTypes } from "entities/EditableTable/Types";
import { IRadioFilterItem } from "shared/types/is-energy";
import { TableTabItem, TabsParams } from "entities/ExcelTable/types";
import { BigLoader } from "components/UI";
import { useActions, useAppSelector } from "app/hooks";
import { useArchiveToReport } from "entities/ExcelTable/hooks/useArchiveToReport";
import { useLocation } from "react-router-dom";
import styles from "./style.module.css";
import { rtkHooks } from "app/store";

type Props = {
    staticItems?:
        | IRadioFilterItem[]
        | ((filter?: { [key: string]: string }) => IRadioFilterItem[]);
    type: TabsParams;
    isShowed: boolean;
    closeShowed: () => void;
    tabs: TableTabItem[];
    createTab: (item: TableTabItem) => void;
};

const defaultValue = {
    // Period: String(new Date().getFullYear()) + "-01-01T00:00:00",
};

const ArchiveContent: FC<Props> = (props) => {
    const { type, isShowed, closeShowed, staticItems, createTab, tabs } = props;
    const location = useLocation();

    const {
        Excel: { resources, guideCreatingForm },
        Login: { currentUser },
    } = useAppSelector((state) => state);

    const { changeActiveIndex } = useActions();

    const config = useMemo(() => {
        return getColumnsApiDictionary(resources)[type];
    }, [type, resources]);

    function onChangeFilter(key: string, value: string) {
        setFilter((prev) => ({ ...prev, [key]: value }));
    }

    function configurateFilter(): { [key: string]: string } {
        if (
            !(
                guideCreatingForm.form.length &&
                location.pathname.includes("balance")
            )
        )
            return defaultValue;

        const type = location.pathname.split("/").at(-1) ?? "";
        const actual = guideCreatingForm.form[0].items[0].items.find((item) =>
            item.value.toLowerCase().includes(type)
        );

        if (actual) {
            let finalValue = { ...defaultValue, Energy: actual.value };
            return finalValue;
        }
        return defaultValue;
    }

    const [filter, setFilter] = useState<{ [key: string]: string }>(() => {
        return configurateFilter();
    });

    useEffect(() => {
        if (isShowed) {
            trigger();
            return;
        }
        const variant = configurateFilter();
        if (JSON.stringify(variant) !== JSON.stringify(filter)) {
            setFilter(variant);
        }
    }, [guideCreatingForm, location.pathname, isShowed]);

    const { data: sources, refetch: trigger } = config.api({
        ...filter,
        energy: "66",
    });

    const actualItems = useMemo<IRadioFilterItem[]>(() => {
        const subItems = staticItems
            ? Array.isArray(staticItems)
                ? staticItems
                : staticItems(filter)
            : [];
        return [...guideCreatingForm.form, ...subItems];
    }, [filter, guideCreatingForm, location.pathname]);

    const generateReportName = useArchiveToReport();
    const [makeTabActive] = rtkHooks.useMakeTabActiveMutation()

    if (!sources) return <BigLoader />;

    return (
        <Flex vertical gap={25}>
            {isShowed && (
                <ArchiveFilters
                    items={actualItems}
                    value={filter}
                    changeValue={onChangeFilter}
                />
            )}

            <ConfigProvider renderEmpty={() => <></>}>
                <Table
                    dataSource={sources}
                    rowKey={'Id'}
                    rowClassName={styles["archive-row"]}
                    tableLayout="fixed"
                    onRow={(record) => {
                        return {
                            onDoubleClick: () => {
                                if ("Id" in record) {
                                    makeTabActive({ type, tabId: record.Id });
                                    // const name = generateReportName(record);
                                    // // changeActiveIndex({
                                    // //     rate: record.Id,
                                    // //     table: record.ReportTableId ?? record.GeneralReportTableId,
                                    // //     name: record.Name ?? name.Name,
                                    // // });
                                    closeShowed();
                                    createTab(record);
                                }
                            },
                        };
                    }}
                    pagination={{
                        pageSize: 10,
                        defaultPageSize: 10,
                        hideOnSinglePage: true,
                        showSizeChanger: false,
                    }}
                    bordered
                    columns={
                        config.columns(
                            !!currentUser?.IsMaster,
                            async (id: number) => {
                                await config.deleteFunction?.(id);
                                trigger();
                            }
                        ) as ColumnTypes
                    }
                />
            </ConfigProvider>
        </Flex>
    );
};

export default ArchiveContent;
