import { ITableRow } from "entities/ExcelTable/types";
import { toast } from "react-toastify";

export type ToNull<T extends object> = { [U in keyof T]: T[U] | null };

export type PartialRecord<K extends keyof any, T> = {
    [P in K]?: T;
};
export const callError = (text: string, time?: number) =>
    toast.error(text, {
        position: "top-center",
        autoClose: time ?? 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
    });

export const callSuccess = (text: string) => {
    toast.success(text, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
    });
};

export const parseObjectToString = (obj: {
    [key: string]: string | number | null;
}): string => {
    return Object.entries(obj).reduce(
        (acc: string, cur: [string, string | number | null]) => {
            const [key, val] = cur;
            return `${acc}&${key}=${val}`;
        },
        ""
    );
};

export const getCurrentDateInString = () => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

export const setDeep = (values: [string, string][], id: string, obj: any) => {
    const objectslav = Object.fromEntries(values);
    // const objectslav = key.reduce<Record<string, string>>((acc, cur, id) => {
    //     acc[cur] = value[id];
    //     return acc;
    // }, {});
    if (obj[id] === undefined) {
        return { ...obj, [id]: objectslav };
    }
    return { ...obj, [id]: { ...obj[id], ...objectslav } };
};

export function parseJson<T>(jsonString: string): T {
    return JSON.parse(jsonString) as T;
}

export const parseDateFromBackToFront = (
    date: string,
    needTime?: boolean,
    prefix?: string
) => {
    const [day, time] = date.split("T");
    const needableTime = time.split(":").slice(0, 2).join(":");
    const currentDate = day.split("-").reverse().join(".");
    if (!needTime) return currentDate;
    return `${currentDate}${prefix ?? " "}${needableTime}`;
};

// превращает дату в формат для бека, todo: поправить время
export const parseDateFromFrontToBack = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
        2,
        "0"
    )}-${String(date.getDate()).padStart(2, "0")}T00:00:00`;
};

export const getDateInterval = (
    dates: [string, string],
    startPrefix?: string,
    endPrefix: string = "-"
): string => {
    const [start, end] = dates.map((el) => parseDateFromBackToFront(el));

    return `${
        startPrefix ? startPrefix + " " : ""
    }${start} ${endPrefix} ${end}`;
};

export const setNewDate = (oldDate: string, newDate: string) => {
    const newParsedDate = newDate.split(".").reverse().join("-");
    const time = oldDate.split("T").at(-1);
    return [newParsedDate, time].join("T");
};

// месяц приходит в порядке 0 - январь, 11 - декабрь
export const getLastMonthDay = (year: number, month: number): number => {
    if ([0, 12].includes(month)) return 31;
    const date = new Date(year, month, 0);
    return date.getDate();
};

export function darkenColor(hex: string, percent: number): string {
    // Убедимся, что hex-код имеет правильный формат
    hex = hex.replace(/^#/, "");

    // Преобразуем hex в RGB
    let r = parseInt(hex.substring(0, 2), 16);
    let g = parseInt(hex.substring(2, 4), 16);
    let b = parseInt(hex.substring(4, 6), 16);

    // Затемняем каждый канал
    r = Math.floor(r * (1 - percent / 100));
    g = Math.floor(g * (1 - percent / 100));
    b = Math.floor(b * (1 - percent / 100));

    // Преобразуем RGB обратно в hex
    const toHex = (c: number) => {
        const hex = c.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export const getAllRowsIds = (rows: ITableRow[]): number[] => {
    let indexes: number[] = [];
    rows.forEach(el => {
        indexes.push(el.PositionId);
        if (el.Children?.length) {
            indexes = indexes.concat(getAllRowsIds(el.Children))
        }
    })
    return indexes
}