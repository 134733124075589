import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ISpItem, ITablePosition } from "../../types";
import { getAuth } from "helpers/getAuth";
import { urlApi } from "app/axiosInstance";
import { IUser, IUserBody } from "entities/Users/types";
import { parseJson } from "shared/helpers";

const auth = getAuth();

export const positionsApi = createApi({
    reducerPath: "positions",
    baseQuery: fetchBaseQuery({
        baseUrl: urlApi + "/rest",
        prepareHeaders: (headers) => {
            if (auth?.headers?.Authorization) {
                headers.set(
                    "Authorization",
                    `Basic ` + auth.headers.Authorization
                );
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),
    tagTypes: ["Users"],
    endpoints: (builder) => ({
        getSp: builder.query<ISpItem[], undefined>({
            query: () => ({
                url: "/spoints/simpleDto/all",
                ...auth,
                async responseHandler(response) {
                    const json = await response.text();
                    const data = parseJson<{ SupplyPoints: ISpItem[] }>(json);
                    return data.SupplyPoints;
                },
            }),
        }),
        getSpMapping: builder.query<ITablePosition[], undefined>({
            query: () => ({
                url: "/event-sp-mapping",
                ...auth,
            }),
        }),
        getUsers: builder.query<IUser[], undefined>({
            query: () => ({
                url: "/users/all",
                ...auth,
            }),
            providesTags: ["Users"],
        }),
        setUser: builder.mutation<IUser, IUserBody>({
            query: (body: IUserBody) => ({
                url: "/users",
                ...auth,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Users"],
        }),
        deleteUser: builder.mutation<IUser, number>({
            query: (query: number) => ({
                url: `/users?id=${query}`,
                ...auth,
                method: "DELETE",
            }),
            invalidatesTags: ["Users"],
        }),
        editPassword: builder.mutation<
            IUser,
            { UserId: number; Password: string }
        >({
            query: (body) => ({
                url: `/users/password`,
                ...auth,
                method: "POST",
                body,
            }),
        }),
        editUser: builder.mutation<IUser, IUserBody>({
            query: (body) => ({
                url: `/users`,
                ...auth,
                method: "PUT",
                body,
            }),
            invalidatesTags: ["Users"],
        }),
    }),
});
