import { FC, useState, memo, useMemo } from "react";
import { TablePrimaryButton } from "entities/ExcelTable/components";
import ArchiveContent from "entities/ExcelTable/components/Archive/ArchiveContent";
import { HurricaneSpinner, Modal } from "shared/components";
import { FileText } from "lucide-react";
import { ArchiveProps } from "entities/ExcelTable/types";
import { availableYears } from "shared/constants/common";
import { useAppSelector } from "app/hooks";
import { useFirstLoading } from "hooks/useFirstLoading";
import { radioMonthDictionary } from "entities/ExcelTable/helpers/archive.constants";

const ReportArchive: FC<ArchiveProps> = memo((props) => {
    const { type, createTab, items } = props;
    const isReport = type === "balance";

    const [open, setOpen] = useState<boolean>(false);
    const { isLoadingState } = useAppSelector((state) => state.Excel);
    const isLoading = useFirstLoading(isLoadingState);

    const onCloseModal = () => {
        setOpen(false);
    };

    const textConfig = useMemo(() => {
        return {
            title: `Архив ${isReport ? "отчетов" : "нормативов"}`,
            description: `Тут находятся все ${
                isReport ? "отчеты" : "нормативы"
            }, которые были закрыты во время работы`,
        };
    }, [isReport]);


    return (
        <>
            <TablePrimaryButton
                onClick={() => setOpen(true)}
                disabled={isLoadingState}
                icon={<FileText fontSize={18} color="#191818" />}
            >
                {isLoading ? <HurricaneSpinner /> : textConfig.title}
            </TablePrimaryButton>
            <Modal
                closedOnAway={false}
                showed={open}
                closeShowed={onCloseModal}
                title={textConfig.title}
                description={textConfig.description}
            >
                {!isLoadingState && (
                    <ArchiveContent
                        type={type}
                        tabs={items}
                        isShowed={open}
                        closeShowed={onCloseModal}
                        createTab={createTab}
                        staticItems={
                            isReport
                                ? [
                                      {
                                          name: "month",
                                          itemsInLine: 4,
                                          items: [
                                              { items: radioMonthDictionary },
                                          ],
                                      },
                                      {
                                          name: "year",
                                          itemsInLine: 4,
                                          startIndex: 3,
                                          items: [{ items: availableYears }],
                                      },
                                  ]
                                : [
                                      {
                                          name: "Mode",
                                          items: [
                                              {
                                                  items: [
                                                      {
                                                          key: "Постоянные",
                                                          value: "CONST",
                                                      },
                                                      {
                                                          key: "Переменная",
                                                          value: "VARIABLE",
                                                      },
                                                  ],
                                              },
                                          ],
                                      },
                                      {
                                          name: "Period",
                                          itemsInLine: 4,
                                          startIndex: 3,
                                          items: [{ items: availableYears }],
                                      },
                                  ]
                        }
                    />
                )}
            </Modal>
        </>
    );
});

export default ReportArchive;
