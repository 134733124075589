import { Flex } from "antd";
import { ArchiveProps, TabsListProps } from "entities/ExcelTable/types";
import { FC } from "react";
import TabsList from "../TabsList";
import { Archive } from "widgets/ExcelTable";
import { HurricaneSpinner } from "shared/components";
import styles from "./styles.module.css";

type Props = TabsListProps & ArchiveProps & { isTabsLoading: boolean };
const TableHeader: FC<Props> = (props) => {
    const {
        items,
        type,
        createTabTitle,
        onCreateItem,
        createTab,
        isTabsLoading,
    } = props;

    const justify = isTabsLoading ? "center" : "space-between";

    return (
        <Flex
            align="flex-start"
            justify={justify}
            gap={12}
            className={styles["table-header"]}
        >
            {isTabsLoading ? (
                <HurricaneSpinner />
            ) : (
                <>
                    <TabsList
                        items={items}
                        type={type}
                        createTabTitle={createTabTitle}
                        onCreateItem={onCreateItem}
                    />

                    <Archive type={type} items={items} createTab={createTab} />
                </>
            )}
        </Flex>
    );
};

export default TableHeader;
