import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { loginSlice } from "../services/login/loginSlice";
import { pointEventsSlice } from "../services/pointEvents/pointEventsSlice";
import { cellMappingSlice } from "services/tableMappings/cellMappingSlice";
import { reducer as excelReducer } from "entities/ExcelTable/slice";
import { positionsApi } from "entities/Positions/api/rtk";
import { balanceApi } from "entities/ISBalance/api/rtk";
import { guideApi } from "entities/ISGuide/api/rtk";
import { reducer as isReducer } from "services/is-energy/is-energySlice";
import { ISApi } from "entities/ExcelTable/api/rtk";

export const store = configureStore({
    reducer: {
        Login: loginSlice.reducer,
        PointEvents: pointEventsSlice.reducer,
        CellMapping: cellMappingSlice.reducer,
        Excel: excelReducer,
        IsEnergy: isReducer,
        [positionsApi.reducerPath]: positionsApi.reducer,
        [balanceApi.reducerPath]: balanceApi.reducer,
        [guideApi.reducerPath]: guideApi.reducer,
        [ISApi.reducerPath]: ISApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: { warnAfter: 128 },
            serializableCheck: false,
        })
            .concat(balanceApi.middleware)
            .concat(positionsApi.middleware)
            .concat(guideApi.middleware)
            .concat(ISApi.middleware),
});

export const rtkHooks = {
    useGetSpQuery: positionsApi.useGetSpQuery,
    useGetSpMappingQuery: positionsApi.useGetSpMappingQuery,
    useGetUsersQuery: positionsApi.useGetUsersQuery,
    useSetUserMutation: positionsApi.useSetUserMutation,
    useDeleteUserMutation: positionsApi.useDeleteUserMutation,
    useEditPasswordMutation: positionsApi.useEditPasswordMutation,
    useEditUserMutation: positionsApi.useEditUserMutation,
    useGetReportBalanceListQuery: balanceApi.useGetReportBalanceListQuery,
    useGetArchiveTemplateListQuery: guideApi.useGetArchiveTemplateListQuery,
    useDeleteArchieTemplateItemMutation:
        guideApi.useDeleteArchieTemplateItemMutation,
    useGetActiveTabsQuery: ISApi.useGetActiveTabsQuery,
    useMakeTabActiveMutation: ISApi.useMakeTabActiveMutation,
    useRemoveTabMutation: ISApi.useRemoveTabMutation,
    useMakeTableActiveMutation: ISApi.useMakeTableActiveMutation,
    useCreateReportMutation: ISApi.useCreateReportMutation,
    useCreateRateMutation: ISApi.useCreateRateMutation,
};

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
