import { NumberInput } from "components/UI";
import { FC, useId } from "react";
import styles from "./stytes.module.scss";
import { Flex } from "antd";
import { useActions, useAppSelector } from "app/hooks";

const FontSizeInput: FC = () => {
    const { fontSize } = useAppSelector((state) => state.Excel);
    const { setFontSize } = useActions();

    const numberedFontSize = +fontSize;
    const id = useId();

    const onButtonClick = (value: number) => {
        if (value < 1) {
            setFontSize("1");
            return;
        }
        setFontSize(String(value));
    };

    return (
        <Flex vertical gap={10} align="center">
            {/* <label htmlFor={id} className={styles['font__label']}>Размер шрифта</label> */}
            <Flex align="center" gap={7}>
                <button
                    onClick={() => onButtonClick(numberedFontSize - 1)}
                    className={styles["font__button"]}
                >
                    -
                </button>
                <NumberInput
                    id={id}
                    className={styles["font__input"]}
                    value={fontSize}
                    noStrictMinHeight
                    onChange={setFontSize}
                />
                <button
                    onClick={() => onButtonClick(numberedFontSize + 1)}
                    className={styles["font__button"]}
                >
                    +
                </button>
            </Flex>
        </Flex>
    );
};

export default FontSizeInput;
