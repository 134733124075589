import { Flex } from "antd";
import { RcFile } from "antd/es/upload";
import { FC, useState } from "react";
import { HurricaneFile, CallbackButton } from "shared/components";
import { withAntdModal } from "shared/hocs/withAntdModal";
import { ModalProps } from "shared/types";
import styles from "./style.module.css";

type ComponentProps = {
    trigger: () => Promise<void>;
    callback: (data: RcFile[]) => Promise<void>;
};
type Props = ComponentProps & ModalProps;
const ResourceConsumptionModal: FC<Props> = (props) => {
    const { trigger, onClose, callback } = props;
    const [fileList, setFileList] = useState<RcFile[]>([]);

    return (
        <Flex gap={15} vertical align="center" className={styles.resourceForm}>
            <HurricaneFile fileList={fileList} modifiedFileList={setFileList} />

            <CallbackButton
                disabled={!fileList.length}
                onClick={() =>
                    callback(fileList)
                        .then(trigger)
                        .then(onClose)
                        .then(() => setFileList([]))
                }
            >
                Отправить
            </CallbackButton>
        </Flex>
    );
};

export default withAntdModal<ComponentProps>(ResourceConsumptionModal);
