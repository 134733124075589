import { FC, useEffect, useMemo, useRef, useState } from "react";
import styles from "./styles.module.css";
import { useClickOutside } from "../useClickOutside";
import { useTableBodyContext, useTableContext } from "../context";
import classNames from "classnames";
import { ITableRow } from "entities/ExcelTable/types";
import { useAppSelector } from "app/hooks";
import { useIndexedContext } from "processes/indexedDBContext";
import { getAllRowsIds } from "shared/helpers";

type Props = {
    order?: number;
    setOpen: () => void;
    isOpen: boolean;
    dataIndex: string;
    lineConfig?: number[];
    row: ITableRow;
    isPicked: boolean;
    columnId: number;
    isSummary: boolean;
};
const ExcelNameCell: FC<Props> = (props) => {
    const {
        order,
        setOpen,
        isOpen,
        columnId,
        dataIndex,
        lineConfig,
        row,
        isSummary,
        isPicked,
    } = props;

    const value = useMemo(() => {
        const result = row.Cells?.find((el) => el.ColumnIds.includes(columnId));
        return result ?? undefined;
    }, [columnId, row]);
    const children = row.Children;

    const [isOpenInput, setIsOpenInput] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState(
        String(value?.CellData.Value ?? "")
    );
    const ref = useRef(null);

    useClickOutside(ref, () => setIsOpenInput(false));

    useEffect(() => {
        setInputValue(String(row.Cells?.[0]?.CellData?.Value ?? ""));
    }, [row.Cells?.[0]?.CellData?.Value]);

    const { pickedCells } = useTableBodyContext();
    const { activeTable } = useTableContext();
    const { updateIndexedTable, updatePointedRow } = useIndexedContext();

    const isActive = useMemo<boolean>(
        () => pickedCells.pickedCells.some((el) => el.id === dataIndex),
        [pickedCells.pickedCells]
    );

    const inputRef = useRef(null);

    const {
        fontSize,
        colors: { name: nameColumnColor, bottom: bottomColumn },
    } = useAppSelector((state) => state.Excel);
    const translated = (order ?? 0) * 10;

    const allLocalRowsIds = useMemo<number[]>(() => {
        if (!row.Children?.length) return [row.PositionId];
        return getAllRowsIds(row.Children).concat(row.PositionId);
    }, [row]);

    const onClickExpandButton = () => {
        setOpen();
        if (activeTable) {
            let currentOpened = activeTable.openedRows;
            if (isOpen) {
                currentOpened = currentOpened.filter(
                    (el) => !allLocalRowsIds.includes(el)
                );
            } else {
                currentOpened = currentOpened.concat(row.PositionId);
            }
            updateIndexedTable(activeTable.tableId, {
                ...activeTable,
                openedRows: currentOpened,
            });
        }
    };
    return (
        <td
            aria-colindex={0}
            data-id={dataIndex}
            ref={ref}
            onDoubleClick={() => {
                if (value === undefined || value.IsMody === true) {
                    setIsOpenInput(true);
                    if (inputRef.current !== null) {
                        const input = inputRef.current as HTMLInputElement;
                        input.focus();
                    }
                }
            }}
            style={{
                backgroundColor: isPicked
                    ? "orange"
                    : isSummary
                    ? bottomColumn
                    : nameColumnColor,
            }}
            className={styles["excel-name-column"]}
        >
            <div
                className={classNames(styles["name-container"], {
                    [styles.pickedRecently]: isActive,
                })}
            >
                <button
                    onClick={() =>
                        updatePointedRow(
                            activeTable?.tableId ?? 0,
                            row.PositionId
                        )
                    }
                    className={classNames(
                        styles["excel__trigger"],
                        "animated",
                        {
                            [styles.pointed]: isPicked,
                        }
                    )}
                />
                {lineConfig?.map((el) => (
                    <div
                        key={el}
                        className={classNames(styles["excel__line-depth"], {
                            [styles["excel__line-start"]]: el === (order ?? 0),
                        })}
                        style={{ left: el * 5 + 5 + "px" }}
                    />
                ))}
                {children?.length && (
                    <button
                        onClick={onClickExpandButton}
                        className={styles["excel-expanded-button"]}
                    >
                        {isOpen ? "-" : "+"}
                    </button>
                )}

                <input
                    style={{ fontSize }}
                    ref={inputRef}
                    value={inputValue}
                    onChange={(e) => setInputValue(e.target.value)}
                    className={classNames(styles.input, {
                        [styles.hidden]: !isOpenInput,
                    })}
                />

                <pre
                    style={{
                        transform: `translateX(${translated}px)`,
                        width: `calc(100% - ${translated}px)`,
                    }}
                    className={classNames(styles["excel-translated-span"], {
                        [styles.none]: isOpenInput,
                    })}
                >
                    {value?.CellData.Value}
                </pre>
            </div>
        </td>
    );
};

export default ExcelNameCell;
