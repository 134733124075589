import classNames from "classnames";
import React, { FC } from "react";
import styles from "./styles.module.css";

type Props = {
    className?: string;
    margin?: number;
};

const VerticalSeparator: FC<Props> = React.memo(({ className, margin }) => {
    return (
        <div
            style={{ marginInline: margin ?? 0 }}
            className={classNames(className, styles.separator)}
        />
    );
});

export default VerticalSeparator;
