import { Modal, Radio } from "antd";
import { FC, useEffect, useState } from "react";
import { HiddenContainer } from "ExcelTable/shared/components";
import ExcelColorsSettings from "./ExcelColorSettings";
import { ColorPallete } from "entities/ExcelTable/types";
import FontSizeInput from "../FontSizeInput";

type Props = {
    colors: ColorPallete;
    changeColor: (key: keyof ColorPallete, color: string) => void;
    isOpen: boolean;
    onClose: () => void;
};

type SettngsVariant = "colors" | "fontSize";
const tabsContent: { name: string; value: SettngsVariant }[] = [
    { name: "Цвета", value: "colors" },
    { name: "Размер шрифта", value: "fontSize" },
];
const tabsValues = tabsContent.map(({ value }) => value);

export type Tabs = (typeof tabsValues)[number];

const SettingsContent: FC<Props> = (props) => {
    const { colors, changeColor, isOpen, onClose } = props;
    const [settings, setSettings] = useState<Tabs>("colors");

    useEffect(() => {
        if (settings !== "colors") {
            setSettings("colors");
        }
    }, []);

    return (
        <Modal
            width={1200}
            footer={<></>}
            open={isOpen}
            onCancel={onClose}
            onClose={onClose}
        >
            <Radio.Group
                onChange={(e) => setSettings(e.target.value)}
                value={settings}
                style={{
                    marginBottom: 8,
                }}
            >
                {tabsContent.map(({ name, value }) => (
                    <Radio.Button key={value} value={value}>
                        {name}
                    </Radio.Button>
                ))}
            </Radio.Group>
            <HiddenContainer isHidden={settings !== "colors"}>
                <ExcelColorsSettings
                    colors={colors}
                    changeColor={changeColor}
                    onClose={onClose}
                />
            </HiddenContainer>
            <HiddenContainer isHidden={settings !== "fontSize"}>
                <FontSizeInput />
            </HiddenContainer>
        </Modal>
    );
};

export default SettingsContent;
