import { Ban, Pencil, SaveIcon, Trash } from "lucide-react";
import { DefaultButtonConfig } from "../Types/actionCell";

import "./styles.scss";

export const defaultConfig: DefaultButtonConfig = {
    delete: {
        buttonType: "primary",
        icon: <Trash width={20} height={20} color="#4B5768" />,
        danger: true,
        success: false,
    },
    save: {
        buttonType: "primary",
        icon: <SaveIcon width={20} height={20} color="#4B5768" />,
        danger: false,
        success: true,
    },
    cancel: {
        buttonType: "default",
        icon: <Ban width={20} height={20} className="danger" color="#4B5768" />,
        danger: true,
        success: false,
    },
    edit: {
        buttonType: "primary",
        icon: <Pencil width={20} height={20} color="#4B5768" />,
        danger: false,
        success: false,
    },
    simple: {
        buttonType: "default",
        icon: null,
        danger: false,
        success: false,
    },
};
