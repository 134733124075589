import React, { createContext, useContext } from "react";
import { IndexedTablesInfo } from "shared/types/is-energy";

interface IIndexedContext {
    deleteIndexedTable: (reportId: number) => void;
    addIndexedTable: (table: IndexedTablesInfo) => void;
    updateIndexedTable: (
        reportId: number,
        updatedData: Partial<IndexedTablesInfo>
    ) => void;
    readIndexedTables: () => void;
    indexedTables: IndexedTablesInfo[];
    updatePointedRow: (tableId: number, rowId: number) => void
}

export const IndexedContext = createContext<IIndexedContext | undefined>(
    undefined
);

export const useIndexedContext = () => {
    const context = useContext(IndexedContext);
    if (!context) throw new Error("context error");
    return context;
};
