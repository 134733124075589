import { Flex } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useFetch } from "hooks";
import { BigLoader } from "components/UI";
import { ITemplate, Template, TemplateResponse } from "entities/ISGuide/types";
import {
    GuideTemlateListHeader,
    GuideTemplateHeader,
    GuideTemplateListItem,
} from "entities/ISGuide/components";
import { getTemplates } from "entities/ISGuide/api";
import TemplateModal from "widgets/ISGuide/TemplateModal";
import styles from "./styles.module.css";
import { useGuideChildrenCells } from "entities/ISGuide/helpers";
import { useFirstLoading } from "hooks/useFirstLoading";

const TemplateGuidePage = () => {
    const [data, setData] = useState<string>("");
    const [activeResources, setActiveResources] = useState<string[]>([]);

    const [activeTemplate, setActiveTemplate] = useState<
        Template | null | undefined
    >(undefined);

    const onCreateTemplate = () => {
        setActiveTemplate(null);
    };

    const {
        data: templates,
        trigger,
        isLoading,
    } = useFetch<ITemplate[], undefined>(getTemplates);

    const {
        isAllClosed,
        toggleChildren,
        replaceGlobalStatus,
        globalOpenStatus,
    } = useGuideChildrenCells(templates);

    const loading = useFirstLoading(isLoading);

    return (
        <Flex vertical gap={25} className={styles.container}>
            <GuideTemplateHeader
                updateString={setData}
                trigger={trigger}
                activeResources={activeResources}
                onChangeResources={setActiveResources}
                callCreatingTemplate={onCreateTemplate}
            />

            <TemplateModal
                currentValue={activeTemplate}
                onCloseModal={(data?: TemplateResponse) => {
                    if (data) {
                        trigger();
                    }
                    setActiveTemplate(undefined);
                }}
            />

            <GuideTemlateListHeader
                closedStatus={isAllClosed}
                replaceStatus={replaceGlobalStatus}
            />

            <Flex vertical gap={10}>
                {loading ? (
                    <BigLoader />
                ) : (
                    (templates ?? []).map((el, id) => (
                        <GuideTemplateListItem
                            template={el}
                            globalOpenStatus={globalOpenStatus}
                            key={id}
                            resourceFilter={activeResources}
                            dataFilter={data}
                            toggleChildren={toggleChildren}
                            onClick={setActiveTemplate}
                        />
                    ))
                )}
            </Flex>
        </Flex>
    );
};

export default TemplateGuidePage;
