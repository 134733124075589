import { FC, forwardRef, useImperativeHandle, useMemo, useState } from "react";
import {
    ConfigProvider,
    Form,
    Modal,
    Spin,
    Table,
    TablePaginationConfig,
    Typography,
} from "antd";
import {
    EditableColumnTypes,
    ColumnTypes,
    Data,
    DataSource,
} from "entities/EditableTable/Types";
import { EditableCell, EditableRow, HeaderTh } from "entities/EditableTable/UI";
import { IActionItem } from "entities/EditableTable/Types/actionCell";
import { useUpdateArray } from "hooks";
import styles from "./style.module.css";
import { TableEditContext } from "./context";
import { DefaultModalFooter } from "shared/components";

type Props = {
    data?: Data[] | null;
    columns: EditableColumnTypes;
    actions?: IActionItem[];
    isCreatingMode?: boolean;
    closeCreatingMode?: () => void;
    refetch?: () => Promise<void>; // refetch - слегка не однозначная сущность, вызывается после того, как произойдет мутация (редактирование, удаление), чтобы получить актуальные данные
};

const components = {
    header: {
        cell: HeaderTh,
    },
    body: {
        cell: EditableCell,
        row: EditableRow,
    },
};

const EditableTable = forwardRef((props: Props, ref) => {
    const {
        data,
        columns,
        actions,
        refetch,
        isCreatingMode,
        closeCreatingMode,
    } = props;

    const [editableDataItems, editableDataItemsActions] =
        useUpdateArray<DataSource>([], isCreatingMode, closeCreatingMode);

    const [pageConfig, setPageConfig] = useState({
        pageNumber: 1,
        pageSize: 10,
    });
    const [deletingCallback, setDeletingCallback] = useState<
        null | (() => void)
    >(null);

    const closeShowed = () => {
        setDeletingCallback(null);
    };

    const ACTUAL_PER_PAGE = pageConfig.pageSize + (isCreatingMode ? 1 : 0);

    const dataSource = useMemo<DataSource[]>(() => {
        if (!data) return [];
        const parsed = data.map((el) => ({ ...el, key: el.Id }));
        if (!isCreatingMode) return parsed;
        return [
            { key: -1, Id: -1 },
            ...parsed.slice(
                (pageConfig.pageNumber - 1) * ACTUAL_PER_PAGE,
                pageConfig.pageNumber * ACTUAL_PER_PAGE
            ),
        ];
    }, [data, isCreatingMode, pageConfig.pageNumber]);

    function triggerPageEdit(id: number, key?: any, value?: any) {
        editableDataItemsActions.toggle(
            dataSource.slice(
                (pageConfig.pageNumber - 1) * ACTUAL_PER_PAGE,
                pageConfig.pageNumber * ACTUAL_PER_PAGE
            ),
            key,
            value
        );
    }

    const paginationOptions = useMemo<TablePaginationConfig | false>(() => {
        if (isCreatingMode) return false;
        return {
            pageSize: ACTUAL_PER_PAGE,
            defaultPageSize: 10,
            hideOnSinglePage: true,
            onChange: (val: number) => {
                setPageConfig((prev) => ({ ...prev, pageNumber: val }));
                editableDataItemsActions.reset();
            },
            showSizeChanger: true,
            pageSizeOptions: ["5", "10", "15", "20"],
            onChangePageSize: 2,
            onShowSizeChange: (_: number, val: number) => {
                setPageConfig((prev) => ({ ...prev, pageSize: val }));
            },
        };
    }, [editableDataItemsActions, setPageConfig, isCreatingMode]);

    const columns2 = columns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onHeaderCell: (data: any) => {
                return {
                    width: data.width,
                    onAddElem: data.onAddElem,
                    isCreatingMode,
                    isRequired: data.required,
                };
            },
            onCell: (record: DataSource) => {
                return {
                    record,
                    editable: col.editable,
                    refetch: refetch,
                    defaultValue: col.defaultValue,
                    editableDataItems: editableDataItems,
                    dataIndex: col.dataIndex,
                    editableDataItemsActions,
                    isRequired: col.required,
                    actions,
                    multiple: col.multiple,
                    options: col.options,
                    type: col.type,
                };
            },
        };
    });

    useImperativeHandle(
        ref,
        () => {
            return {
                changeField(id: number, key: string, value: any) {
                    editableDataItemsActions.update(id, key, value);
                },
            };
        },
        []
    );


    
    if (data === null || data === undefined) return <Spin size="large" />;
    if (!data.length) return <h1>Данные не найдены</h1>;

    return (
        <TableEditContext.Provider
            value={{
                setAllPageEdit: triggerPageEdit,
                openShowed: (callback: () => void) => {
                    setDeletingCallback(() => callback);
                },
            }}
        >
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#1890ff",
                    },
                    components: {
                        Table: {
                            cellPaddingBlock: 8,
                            cellPaddingInline: 8,
                            colorBorder: "#e22739",
                        },
                        Pagination: {
                            colorIconHover: "#e22739",
                            colorBorder: "#e22739",
                            colorBgBase: "#e22739",
                            colorBorderBg: "#e22739",
                            colorInfoTextHover: "#e22739",
                            colorPrimaryBorder: "#e22739",
                        },
                    },
                }}
                renderEmpty={() => <></>}
            >
                <Modal
                    title="Подтвердите удаление"
                    width={700}
                    open={!!deletingCallback}
                    centered
                    footer={() => (
                        <DefaultModalFooter
                            onAccept={() => {
                                deletingCallback?.();
                                closeShowed();
                            }}
                            onReject={closeShowed}
                        />
                    )}
                >
                    <Typography.Text strong>
                        Вы действительно хотите удалить элемент?
                    </Typography.Text>
                </Modal>

                <Table
                    components={components}
                    className={styles.table}
                    dataSource={dataSource}
                    tableLayout="fixed"
                    pagination={paginationOptions}
                    bordered
                    onRow={(record) => {
                        return { data: record, columns: columns2 } as any;
                    }}
                    columns={columns2 as ColumnTypes}
                />
            </ConfigProvider>
        </TableEditContext.Provider>
    );
});

export default EditableTable;
