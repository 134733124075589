import { ApiModule } from "shared/ApiModule";
import { IBalanceResponse, BalanceReport } from "../types";

export const getReportsArchive = async (params?: {
    [key: string]: string;
}): Promise<BalanceReport[]> => {
    const filter: Record<string, string> = {
        energySource: params?.EnergySourceId ?? "",
    };
    ["month", "year"].forEach((el) => {
        if (params?.[el]) {
            filter[el] = params[el];
        }
    });

    const data = await ApiModule.get<IBalanceResponse[]>(
        `/ReportTemplate/filter`,
        filter
    );
    return (data || []).reverse().map((el) => ({
        ...el,
        ReportTableId: el.GeneralReportTableId,
        key: el.Id,
    }));
};

export const deleteArchiveBalanceReport = async (id: number) => {
    await ApiModule.delete(`/ReportTemplate`, { reportTemplateId: id });
};
