import { useAppSelector } from "app/hooks";
import { IGuideRate, TableTabItem } from "../types";
import { resourceDictionary } from "entities/ISGuide/types";
import { guideDictionary } from "../helpers/constants";
import { getReportName2 } from "ExcelTable/helpers/methods";

const isNotUndefinedType = (
    item: any
): item is any & { Id: number; EnergySourceId: number } => {
    return "Id" in item && "EnergySourceId" && "ReportTableId" in item;
};

const isIGuideRate = (item: any): item is IGuideRate => {
    return "Mode" in item;
};

type ReturnRype = (val: any) => TableTabItem;

export const useArchiveToReport = (): ReturnRype => {
    const { resources } = useAppSelector((state) => state.Excel);

    const generateReportName = (val: any): TableTabItem => {
        if (!isNotUndefinedType(val)) {
            const res = getReportName2(val);
            return {
                ShortName: res.split("-")[0].split("[")[0],
                Name: res,
                filtersState: {},
                Id: 0,
                ReportTableId: 0,
            };
        }
        const current = resources.find(
            (el) => el.EnergySourceId === val.EnergySourceId
        );
        if (isIGuideRate(val)) {
            return {
                ShortName: `${
                    guideDictionary[current?.AxisCode ?? ""]?.short ?? ""
                } ${val.StartDate.split("-")[0]} ${
                    resourceDictionary[val.Mode].short
                }`,
                Name: `${current?.AxisName ?? ""} ${
                    val.StartDate.split("-")[0]
                } / ${resourceDictionary[val.Mode].many} нормы`,
                filtersState: {},
                Id: val.Id,
                ReportTableId: val.ReportTableId,
            };
        }
        return {
            ShortName: val.Name
                ? val.Name.split("-")[0].split("[")[0]
                : `${current?.AxisName} отчет`,
            Name: val.Name ?? `${current?.AxisName} отчет`,
            filtersState: {},
            Id: val.Id,
            ReportTableId: val.ReportTableId,
        };
    };

    // const currentReportName = useMemo<TableTabItem>(() => {
    //     return generateReportName(item);
    // }, [item]);

    return generateReportName;
};
