import { FC } from "react";
import Crumb from "../Crumb";
import VerticalSeparator from "shared/components/VerticalSeparator";
import { Flex } from "antd";

type Props = {
    isLast: boolean;
    link: { name: string; link: string };
    pathname: string;
};

const IsEnergyCrumb: FC<Props> = (props) => {
    const { isLast, link, pathname } = props;

    return (
        <Flex>
            <Crumb
                noChevron
                style={{ fontSize: 16 }}
                crumb={{
                    label: link.name,
                    url: link.link,
                    isActive: pathname === link.link,
                }}
            />

            {!isLast && <VerticalSeparator margin={8} />}
        </Flex>
    );
};

export default IsEnergyCrumb;
