import { availableKeys } from "entities/ExcelTable/helpers/constants";
import { RefObject, useEffect, useRef, useState } from "react";
import { CellDirection } from "shared/types";

type PickedCell = { id: string; parentRow: number; value?: string };

export interface IPickedConfig {
    pickedCells: PickedCell[];
    operation: string;
}

export const usePickedCells = (
    table: RefObject<HTMLTableElement>,
    container: RefObject<HTMLDivElement>,
    tableBody: RefObject<HTMLTableSectionElement>,
    callback?: () => void,
    dependsOn?: string | null
) => {
    const [data, setData] = useState<IPickedConfig>(() => {
        return { pickedCells: [], operation: "" };
    });
    // замыкаине не работает в слушателе событий с обычным useState, но прекрасно работает с useRef
    const pickedCellsRef = useRef<PickedCell[]>([]);

    const setPickedCell = (ids: PickedCell[]) => {
        pickedCellsRef.current = ids;
        setData((prev) => ({ ...prev, pickedCells: ids }));
    };

    function setDataToEditableCell(val: string) {
        setPickedCell(data.pickedCells.map((el) => ({ ...el, value: val })));
    }

    useEffect(() => {
        callback?.();
        if (data.operation.length > 0) {
            setData((prev) => ({ ...prev, operation: "" }));
        }
    }, [data.pickedCells]);

    function horizontalIncreaseCell(
        direction: "left" | "right",
        element: Element,
        row: HTMLTableRowElement
    ) {
        const closestTd = element.closest("td");
        if (!closestTd) {
            setPickedCell([]);
            return;
        }
        const array = Array.from(row.children);
        if (direction === "left") {
            array.reverse();
        }
        const index = array.indexOf(closestTd);
        if (index < array.length) {
            const next = array[index + 1] as HTMLTableCellElement;
            setPickedCell([
                {
                    id: next.dataset.id ?? "",
                    parentRow: +(row.dataset.id ?? -1),
                },
            ]);
        }
    }

    function increasePickedCell(e: KeyboardEvent) {
        const direction: CellDirection | undefined = availableKeys[e.key];
        if (!pickedCellsRef.current.length || !direction) return;
        const active = pickedCellsRef.current[0];
        const element = document.querySelector(`[data-id="${active.id}"]`);
        if (!element) return;
        const [rowInfo, columnInfo] = active.id.split(":");
        const row = element.closest("tr") as HTMLTableRowElement;
        if (["left", "right"].includes(direction)) {
            horizontalIncreaseCell(direction as "left" | "right", element, row);
            return;
        }

        let array = Array.from(table.current?.children || []);
        if (!array.length) return;
        array = Array.from(array.at(-1)?.children || []).filter(
            (el) => (el as HTMLTableRowElement).dataset.status !== "none"
        );
        if (direction === "top") {
            array.reverse();
        }
        const index = array.indexOf(row) + 1;
        if (index <= array.length - 1) {
            const elem = array[index] as HTMLTableRowElement;
            const dataIndex = [
                (array[index] as HTMLTableRowElement).dataset.row || "",
                columnInfo,
            ].join(":");

            if (!container.current || !tableBody.current) return;
            const bounfingClinetY = element.getBoundingClientRect().y;
            if (
                direction === "bottom" &&
                bounfingClinetY + (50 + 2) * 2 > document.body.clientHeight
            ) {
                container.current.scrollTop += 43;
            } else if (
                direction === "top" &&
                bounfingClinetY - (40 + 2) <
                    container.current.scrollTop +
                        tableBody.current.getBoundingClientRect().y
            ) {
                container.current.scrollTop -= 43;
            }

            setPickedCell([
                { id: dataIndex, parentRow: +(elem.dataset.id ?? -1) },
            ]);
        }
    }

    const setOperation = (value: string) => {
        if (!data.operation) {
            setData((prev) => ({ ...prev, operation: value }));
        }
    };

    useEffect(() => {
        if (!dependsOn) return;
        if (data.pickedCells?.[0].id !== dependsOn) {
            const element = document.querySelector(`[data-id="${dependsOn}"]`);
            if (!element) return;
            const row = (element as HTMLTableCellElement).closest(
                "tr"
            ) as HTMLTableRowElement;
            setData((prev) => ({
                ...prev,
                pickedCells: [
                    { id: dependsOn, parentRow: +(row.dataset.id ?? -1) },
                ],
            }));
        }
    }, [dependsOn]);

    return {
        pickedCells: data,
        setOperation,
        setData,
        setPickedCell,
        pickedCellsRef,
        increasePickedCell,
        setDataToEditableCell,
    };
};
