import { urlApi } from "app/axiosInstance";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth } from "helpers/getAuth";
import { IGuideRate } from "entities/ExcelTable/types";

const auth = getAuth();

export const guideApi = createApi({
    reducerPath: "guide",
    baseQuery: fetchBaseQuery({
        baseUrl: urlApi + "/rest/RateTemplate",
        prepareHeaders: (headers) => {
            if (auth?.headers?.Authorization) {
                headers.set(
                    "Authorization",
                    `Basic ` + auth.headers.Authorization
                );
            }
            headers.set("Content-Type", "application/json");
            return headers;
        },
    }),

    tagTypes: ["Guide"],
    endpoints: (builder) => ({
        getArchiveTemplateList: builder.query<
            IGuideRate[],
            Record<string, string>
        >({
            query: (body: Record<string, string>) => ({
                url: "list",
                method: "POST",
                body: Object.fromEntries(
                    Object.entries(body).filter(([_, value]) => !!value)
                ),
            }),
            keepUnusedDataFor: 0
        }),
        deleteArchieTemplateItem: builder.mutation<undefined, number>({
            query: (query: number) => ({
                url: ``,
                params: { rateTemplateId: query },
                method: "DELETE",
            }),
            invalidatesTags: ["Guide"],
        }),

        // deleteUser: builder.mutation<IUser, number>({
        //     query: (query: number) => ({
        //         url: `/users?id=${query}`,
        //         ...auth,
        //         headers: {
        //             Authorization: `Basic ${auth.headers.Authorization}`,
        //             "Content-Type": "application/json",
        //         },
        //         method: "DELETE",
        //     }),
        //     invalidatesTags: ["Users"],
        // }),
    }),
});
