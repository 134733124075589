/* eslint-disable no-restricted-globals */
import axios from "axios";
import { callError } from "shared/helpers";

export const urlApi =
    location.hostname === "localhost"
        ? "https://aux-lvnp.enrsoft.ru/api"
        : // для публикации в закрытом контуре lukoil
          "https://" + location.hostname + "/api";

type ErrorWithMessage = {
    response: {
        data: { Message: string };
    };
};
function errorHasMessage(error: unknown): error is ErrorWithMessage {
    return "response" in (error as any);
}

const errorHandler = (error: unknown) => {
    console.log(error);
    let message = "Возникла ошибка при выполнении запроса";
    if (errorHasMessage(error)) {
        message = error.response.data.Message;
    }
    callError(message);

    return Promise.reject(error);
};

const isNotLocalhost = location.hostname !== "localhost";

export const BaseInstanse = axios.create({
    withCredentials: true,
    baseURL: `${urlApi}`,
    headers: { "Content-Type": "multipart/form-data" },
});

export const RestInstanse = axios.create({
    withCredentials: true,
    baseURL: `${urlApi}/rest`,
    // headers : { "Authorization" : "BasicCustom", }
    // headers: { "Content-Type": "multipart/form-data","Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS", }
});

RestInstanse.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if ([302, 500, 400].includes(error?.request?.status))
        return errorHandler(error);
    }
);
