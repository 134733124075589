import { useAppSelector, useDebounce } from "app/hooks";
import { Flex } from "antd";
import { FC, useMemo, useState } from "react";
import { useEffectSkipFirstRender, useInput } from "hooks";
import { HurricaneSelect, SearchInput } from "shared/components";
import RedButton from "shared/components/RedButton";
import { HurricaneSelectOption } from "shared/types/filters";
import { ICreatingFormResponse } from "entities/ISGuide/types";
import { ResourceConsumptionModal } from "widgets/ExcelTable";
import { loadResourceTemplates } from "entities/ISGuide/api";

type Props = {
    updateString: (val: string) => void;
    callCreatingTemplate: () => void;
    trigger: () => Promise<void>;
    activeResources: string[];
    onChangeResources: (val: string[]) => void;
};

const GuideTemplateHeader: FC<Props> = (props) => {
    const {
        updateString,
        callCreatingTemplate,
        activeResources,
        trigger,
        onChangeResources,
    } = props;
    const [data, setData] = useInput("");
    const value = useDebounce<string>(data, 600);
    const { resources } = useAppSelector((state) => state.Excel);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const options = useMemo<HurricaneSelectOption[]>(
        () =>
            resources.reduce<HurricaneSelectOption[]>(
                (acc: HurricaneSelectOption[], cur: ICreatingFormResponse) => {
                    if (acc.some((el) => el.value === cur.EnergySourceName))
                        return acc;
                    return acc.concat({
                        label: cur.EnergySourceName,
                        value: cur.EnergySourceName,
                    });
                },
                []
            ),
        [resources]
    );

    useEffectSkipFirstRender(() => {
        updateString(value);
    }, [value]);

    return (
        <Flex align="flex-end" gap={15}>
            <ResourceConsumptionModal
                isOpen={isModalVisible}
                trigger={trigger}
                title="Загрузка шаблонов энергоресурсов"
                centered
                callback={loadResourceTemplates}
                width={700}
                needFooter={false}
                onClose={() => setIsModalVisible(false)}
            />
            <SearchInput
                className="search"
                compacted
                height={55}
                width={150}
                allowClear
                style={{ width: 395, height: 55 }}
                placeholder="Умный поиск"
                value={data}
                onChange={setData}
            />

            <HurricaneSelect
                placeholder="Энергоресурсы"
                value={activeResources}
                onChange={onChangeResources}
                options={options}
                showSearch
                style={{ width: 300 }}
                multiple
            />

            <RedButton onClick={callCreatingTemplate}>
                Добавить шаблон
            </RedButton>

            <RedButton onClick={() => setIsModalVisible(true)} variant="gray">
                Загрузка шаблонов
            </RedButton>
        </Flex>
    );
};

export default GuideTemplateHeader;
