export const getAuth = () => {
    const login = localStorage?.getItem("username") || "";
    const password = localStorage?.getItem("password") || "";
    const isNotLocalhost = window.location.hostname !== "localhost";
    return isNotLocalhost
        ? {}
        : {
              auth: {
                  username: login,
                  password: password,
              },
              headers: {
                  Authorization: btoa(
                      unescape(encodeURIComponent(`${login}:${password}`))
                  ),
              },
          };
};
