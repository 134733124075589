import { Flex, Select, SelectProps } from "antd";
import classNames from "classnames";
import { FC, useId } from "react";
import "./styles.scss";
import { HurricaneSelectOption } from "shared/types/filters";

type Options = {
    isLukoil?: boolean;
};

const HurricaneSelect: FC<
    Omit<SelectProps, "options"> &
        Options & {
            options?: HurricaneSelectOption[];
            multiple?: boolean;
            label?: string;
            minWidth?: number;
        }
> = (props) => {
    const {
        isLukoil,
        className,
        notFoundContent,
        optionFilterProp,
        multiple,
        minWidth,
        label,
        id,
        ...rest
    } = props;

    const calculatedId = useId();

    return (
        <Flex vertical gap={10} align="center" style={{ minWidth }}>
            {!!label && (
                <label
                    htmlFor={id ?? calculatedId}
                    className={"hurricane-label"}
                >
                    {label}
                </label>
            )}
            <Select
                {...rest}
                className={classNames(className, "hurricane-select", {
                    lukoil: isLukoil,
                })}
                id={id ?? calculatedId}
                mode={multiple ? "multiple" : undefined}
                optionFilterProp={optionFilterProp || "label"}
                notFoundContent={notFoundContent || "Не найдено"}
            ></Select>
        </Flex>
    );
};

export default HurricaneSelect;
